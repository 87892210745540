import apiInstance from "@/http";
import entityNormalizer from "@/store/util/entity-normalizer";
import userService from "@/http/service/user-service";

export default {
    state: {
        chosen: [],
        tectonics: {},
        mineralDeposits: {},
        wells: {},
        samples: {},
        thinSections: {},
        thinSectionPlots: {}
    },
    mutations: {
        SET_TECTONICS(state, payload) {
            state.tectonics = payload
        },
        SET_DEPOSITS(state, payload) {
            state.mineralDeposits = payload
        },
        SET_WELLS(state, payload) {
            state.wells = payload
        },
        SET_SAMPLES(state, payload) {
            state.samples = payload
        },
        SET_THIN_SECTIONS(state, payload) {
            state.thinSections = payload
        },
        SET_THIN_SECTION_PLOTS(state, payload) {
            state.thinSectionPlots = payload
        },
        ADD_DEPOSIT(state, deposit) {
            state.mineralDeposits = {
                ...state.mineralDeposits,
                [deposit.id]: entityNormalizer.normalizeDeposit(deposit)
            }
            state.tectonics[deposit.tectonic_confinement]
                .mineral_deposits.push(deposit.id)
        },
        ADD_WELL(state, well) {
            state.wells = {
                ...state.wells,
                [well.id]: entityNormalizer.normalizeWell(well)
            }
            state.mineralDeposits[well.mineral_deposit]
                .wells.push(well.id)
        },
        ADD_SAMPLE(state, sample) {
            state.samples = {
                ...state.samples,
                [sample.id]: entityNormalizer.normalizeSample(sample)
            }
            state.wells[sample.well]
                .samples.push(sample.id)
        },
        ADD_THIN_SECTION(state, thinSection) {
            state.thinSections = {
                ...state.thinSections,
                [thinSection.id]: entityNormalizer.normalizeThinSection(thinSection)
            }
            state.samples[thinSection.sample]
                .thin_sections.push(thinSection.id)
        },
        ADD_THIN_SECTION_PLOT(state, thinSectionPlot) {
            state.thinSectionPlots = {
                ...state.thinSectionPlots,
                [thinSectionPlot.id]: entityNormalizer.normalizeThinSectionPlot(thinSectionPlot)
            }
            state.thinSections[thinSectionPlot.thin_section]
                .thin_section_plots.push(thinSectionPlot.id)
        },
        DELETE_DEPOSIT(state, payload) {
            const deposit = state.mineralDeposits[payload]
            for (const well of deposit.wells) {
                this.commit('DELETE_WELL', well)
            }
            const tectonic = state.tectonics[deposit.tectonic_confinement]
            tectonic.mineral_deposits = tectonic.mineral_deposits.filter(id => id !== deposit.id)
            const deposits = {...state.mineralDeposits}
            delete deposits[payload]
            state.mineralDeposits = deposits
        },
        DELETE_WELL(state, payload) {
            const well = state.wells[payload]
            for (const sample of well.samples) {
                this.commit('DELETE_SAMPLE', sample)
            }
            const deposit = state.mineralDeposits[well.mineral_deposit]
            deposit.wells = deposit.wells.filter(id => id !== well.id)
            const wells = {...state.wells}
            delete wells[payload]
            state.wells = wells
        },
        DELETE_SAMPLE(state, payload) {
            const sample = state.samples[payload]
            for (const thinSection of sample.thin_sections) {
                this.commit('DELETE_THIN_SECTION', thinSection)
            }
            const well = state.wells[sample.well]
            well.samples = well.samples.filter(id => id !== sample.id)
            const samples = {...state.samples}
            delete samples[payload]
            state.samples = samples
        },
        DELETE_THIN_SECTION(state, payload) {
            const thinSection = state.thinSections[payload]
            for (const thinSectionPlot of thinSection.thin_section_plots) {
                this.commit('DELETE_THIN_SECTION_PLOT', thinSectionPlot)
            }
            const sample = state.samples[thinSection.sample]
            sample.thin_sections = sample.thin_sections.filter(id => id !== thinSection.id)
            const thinSections = {...state.thinSections}
            delete thinSections[payload]
            state.thinSections = thinSections
        },
        DELETE_THIN_SECTION_PLOT(state, payload) {
            const thinSectionPlot = state.thinSectionPlots[payload]
            const thinSection = state.thinSections[thinSectionPlot.thin_section]
            thinSection.thin_section_plots = thinSection.thin_section_plots.filter(id => id !== thinSectionPlot.id)
            const thinSectionPlots = {...state.thinSectionPlots}
            delete thinSectionPlots[payload]
            state.thinSectionPlots = thinSectionPlots
        },
        ADD_THIN_SECTION_PLOT_CROSSED_IMAGE(state, payload) {
            state.thinSectionPlots[payload.thin_section_plot].crossed_images.push(payload)
        },
        ADD_THIN_SECTION_PLOT_PARALLEL_IMAGE(state, payload) {
            state.thinSectionPlots[payload.thin_section_plot].parallel_image = payload
        },
        SET_CHOSEN_ITEMS(state, payload) {
            state.chosen.push(payload)
        },
        REMOVE_CHOSEN_ITEMS(state) {
            state.chosen.pop()
        },
        COLLAPSE_TECTONIC(state, payload) {
            state.tectonics[payload].show = true
        },
        COLLAPSE_DEPOSIT(state, payload) {
            const deposit = state.mineralDeposits[payload]
            this.commit('COLLAPSE_TECTONIC', deposit.tectonic_confinement)
            deposit.show = true
        },
        COLLAPSE_WELL(state, payload) {
            const well = state.wells[payload]
            this.commit('COLLAPSE_DEPOSIT', well.mineral_deposit)
            well.show = true
            well.showSample = true
        },
        COLLAPSE_SAMPLE(state, payload) {
            const sample = state.samples[payload]
            this.commit('COLLAPSE_WELL', sample.well)
            sample.show = true
        },
        COLLAPSE_THIN_SECTION(state, payload) {
            const thinSection = state.thinSections[payload]
            this.commit('COLLAPSE_SAMPLE', thinSection.sample)
            thinSection.show = true
            thinSection.showPlots = true
        },
        COLLAPSE_THIN_SECTION_PLOT(state, payload) {
            const thinSectionPlot = state.thinSectionPlots[payload]
            this.commit('COLLAPSE_THIN_SECTION', thinSectionPlot.thin_section)
            thinSectionPlot.show = true
        },
        ADD_ANALYZE(state, payload) {
            state.thinSectionPlots[payload.thinSectionPlotId].analyze = payload.analyze
        },
        UPDATE_THIN_SECTION_PLOT(state, payload) {
            state.thinSectionPlots[payload.id] = entityNormalizer.normalizeThinSectionPlot(payload)
        },
        UPDATE_THIN_SECTION(state, thinSection) {
            state.thinSections[thinSection.id] = entityNormalizer.normalizeThinSection(thinSection)
        },
        UPDATE_SAMPLE(state, sample) {
            state.samples[sample.id] = entityNormalizer.normalizeSample(sample)
        },
        UPDATE_WELL(state, well) {
            state.wells[well.id] = entityNormalizer.normalizeWell(well)
        },
        UPDATE_DEPOSIT(state, deposit) {
            state.mineralDeposits[deposit.id] = entityNormalizer.normalizeDeposit(deposit)
        },
    },
    actions: {
        async initStore({commit, rootState}) {
            const userDetails = await userService.getDetails().then(({data}) => {
                const userDetails = rootState.user.userDetails
                userDetails.firstName = data.first_name
                userDetails.lastName = data.last_name
                userDetails.isActivated = data.is_activated
                return userDetails
            })

            if (!userDetails.isActivated) {
                return
            }

            const tectonics = {};
            let tectonicsData = await apiInstance.get('/tectonic')
            for (const tectonic of tectonicsData.data) {
                tectonics[tectonic.id] = entityNormalizer.normalizeTectonic(tectonic)
            }
            commit('SET_TECTONICS', tectonics)

            const mineralDeposits = {};
            const depositsData = await apiInstance.get('/mineral-deposit')
            for (const deposit of depositsData.data) {
                mineralDeposits[deposit.id] = entityNormalizer.normalizeDeposit(deposit)
            }
            commit('SET_DEPOSITS', mineralDeposits)

            const wells = {};
            const wellsData = await apiInstance.get('/well')
            for (const well of wellsData.data) {
                wells[well.id] = entityNormalizer.normalizeWell(well)
            }
            commit('SET_WELLS', wells)

            const samples = {};
            const samplesData = await apiInstance.get('/sample')
            for (const sample of samplesData.data) {
                samples[sample.id] = entityNormalizer.normalizeSample(sample)
            }
            commit('SET_SAMPLES', samples)

            const thinSections = {};
            const thinSectionsData = await apiInstance.get('/thin-section')
            for (const thinSection of thinSectionsData.data) {
                thinSections[thinSection.id] = entityNormalizer.normalizeThinSection(thinSection)
            }
            commit('SET_THIN_SECTIONS', thinSections)

            const thinSectionPlots = {};
            const thinSectionPlotsData = await apiInstance.get('/thin-section-plot')
            for (const thinSectionPlot of thinSectionPlotsData.data) {
                thinSectionPlots[thinSectionPlot.id] = entityNormalizer.normalizeThinSectionPlot(thinSectionPlot)
            }
            commit('SET_THIN_SECTION_PLOTS', thinSectionPlots)
        }
    },
    getters: {
        tectonics: (state) => {
            return state.tectonics
        },
        tectonicDeposits: (state => (tectonicId) => (
            state.tectonics[tectonicId].mineral_deposits.map(depositId => state.mineralDeposits[depositId])
        )),
        depositWells: (state => (depositId) => (
            state.mineralDeposits[depositId].wells.map(wellId => state.wells[wellId])
        )),
        wellSamples: (state => (wellId) => (
            state.wells[wellId].samples.map(sampleId => state.samples[sampleId])
        )),
        sampleThinSections: (state => (sampleId) => (
            state.samples[sampleId].thin_sections.map(thinSectionId => state.thinSections[thinSectionId])
        )),
        thinSectionPlots: (state => (thinSectionId) => (
            state.thinSections[thinSectionId].thin_section_plots.map(thinSectionPlotId => state.thinSectionPlots[thinSectionPlotId])
        )),
        getTectonic: (state => (tectonicId) => (
            state.tectonics[tectonicId]
        )),
        getDeposit: (state => (depositId) => (
            state.mineralDeposits[depositId]
        )),
        getWell: (state => (wellId) => (
            state.wells[wellId]
        )),
        getSample: (state => (sampleId) => (
            state.samples[sampleId]
        )),
        getThinSection: (state => (thinSectionId) => (
            state.thinSections[thinSectionId]
        )),
        getThinSectionPlot: (state => (thinSectionPlotId) => (
            state.thinSectionPlots[thinSectionPlotId]
        )),
        chosen: (state) => {
            if (state.chosen.length === 0) {
                return null
            }
            return state.chosen.at(-1)
        },
    }
}
