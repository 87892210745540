<template>
  <v-container fluid class="tectonic-wrapper pa-5">
    <v-row>
      <v-col cols="10">
        <div class="domain-header-text">
          Данные о тектонике
        </div>
        <TectonicCard :id="Number(id)"/>
        <div class="domain-child">
          Месторождения
        </div>
        <div class="domain-child__item"
             v-for="deposit of mineralDeposits"
             :key="deposit.id"
             @click="navigateTo(deposit)"
        >{{ deposit.name }}
        </div>
      </v-col>
      <v-col cols="2">
        <DepositCreateUpdateModalWindow
          :tectonic-id="id"
        ></DepositCreateUpdateModalWindow>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DepositCreateUpdateModalWindow from "@/components/modal/list/DepositCreateUpdateModalWindow.vue";
import {mapGetters, mapMutations} from "vuex";
import TectonicCard from "@/components/domain/TectonicCard.vue";

export default {
  name: "TectonicView",
  components: {TectonicCard, DepositCreateUpdateModalWindow},
  props: ['id'],
  methods: {
    ...mapMutations(['COLLAPSE_TECTONIC']),
    navigateTo(item) {
      this.$router.push(item.link)
    },
  },
  computed: {
    ...mapGetters(['tectonicDeposits', 'getTectonic']),
    tectonic() {
      this.COLLAPSE_TECTONIC(this.id)
      return this.getTectonic(this.id)
    },
    mineralDeposits() {
      return this.tectonicDeposits(this.id)
    },
  },
  watch: {
    id: {
      handler: function () {
        this.COLLAPSE_TECTONIC(this.id)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>