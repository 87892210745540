<template>
  <div>
    <DepositCard :id="well.mineral_deposit" />
    <div>
      <div class="domain">
        <div class="domain__title">
          Скважина
        </div>
        <div class="domain__text" @click="navigate">
          {{ well.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DepositCard from "@/components/domain/DepositCard.vue";

export default {
  name: "WellCard",
  components: {DepositCard},
  props: {
    id: {
      type: Number,
      required: true
    },
    isRecursive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getWell']),
    well() {
      return this.getWell(this.id)
    }
  },
  methods: {
    navigate() {
      if (this.$route.name === 'Well') {
        return
      }
      this.$router.push(this.well.link)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>