<template>
  <v-container fluid class="fill-height">
    <v-row class="justify-center align-self-end" no-gutters>
      <v-col cols="8">
        <p>
          В левой части будет отображаться дерево, по которому Вы сможете ориентироваться и находить нужные Вам шлифы.
        </p>
        <p>
          В правой части будет находиться рабочая область, в которой будет отображаться процесс распознавания
          петрографических шлифов.
        </p>
        <p>
          Для запуска анализа необходимо загрузить изображения шлифа при необходимом увеличении, отснятые в скрещенных
          николях с поворотом предметного столика микроскопа, и 1 изображение шлифа, отснятое с выключенном
          анализатором, на котором необходимо показать масштабную линейку.
        </p>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="auto">
        <v-card
          width="300"
          class="image-card"
        >
          <img v-img="" style="width: 100%; height: 250px"
               src="@/assets/img/description/crop0.jpg" alt="">
          <v-card-title
            class="image-card__title justify-center"
          >
            Угол поворота: 0
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-card
          width="300"
          class="image-card"
        >
          <img v-img="" style="width: 100%; height: 250px"
               src="@/assets/img/description/crop5.jpg" alt="">
          <v-card-title
            class="image-card__title justify-center"
          >
            Угол поворота: 5
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="auto">
        <v-card
          width="300"
          class="image-card"
        >
          <img v-img="" style="width: 100%; height: 250px"
               src="@/assets/img/description/cropll.jpg" alt="">
          <v-card-title
            class="image-card__title justify-center"
          >
            Масштаб: 0.5 мм
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="justify-center align-self-end" no-gutters>
      <v-col cols="auto">
        <button class="next-button" @click="next">Далее</button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Description",
  methods: {
    next() {
      this.$router.push({
        name: 'Home'
      })
    }
  }
}
</script>

<style scoped lang="scss">
p {
  font-size: 25px;
  line-height: 40px;
  text-align: justify;
  text-indent: 30px;
}

.next-button {
  font-size: 28px;
  background-color: $base-color;
  padding: 14px 100px;
  border-radius: 12px;
  color: white;
}
</style>