<template>
  <v-dialog
    v-model="value"
    max-width="500px">
    <template v-slot:activator="{ on }">
      <button
        v-if="update"
        class="domain-edit-button"
        v-on="on"
      >
        Редактировать
      </button>
      <button
        v-else
        class="domain-add-button"
        v-on="on"
      >Добавить месторождение
      </button>
    </template>
    <v-card>
      <DepositForm
        :tectonic-id="tectonicId"
        :update="update"
        :deposit-id="depositId"
        @close-window="close"
      ></DepositForm>
    </v-card>
  </v-dialog>
</template>

<script>
import DepositForm from "@/components/modal/form/DepositCreateUpdateForm.vue";

export default {
  name: "DepositCreateUpdateModalWindow",
  components: {DepositForm},
  props: {
    tectonicId: {
      required: true,
      type: [Number, String]
    },
    update: {
      default: false
    },
    // used for update action, otherwise it's undefined
    depositId: {
      required: false,
      type: [Number, String]
    },
  },
  data() {
    return {
      value: false,
    }
  },
  methods: {
    close() {
      this.value = false
    }
  },
}
</script>
