<template>
  <v-container class="login fill-height">
    <v-row class="justify-center align-center">
      <form class="login-form">
        <v-row class="justify-center flex-column align-center">
          <p class="login-title">Авторизация</p>
          <p class="login-subtitle">Войдите в систему и начните <br> обработку своих данных</p>
          <div class="input-wrapper">
            <input
              type="text"
              name="login"
              placeholder="Логин"
              v-model="username"
            >
            <input
              type="password"
              name="password"
              placeholder="Пароль"
              v-model="password"
            >
            <v-btn
              class="submit-button"
              @click.prevent="login"
              :disabled="waitForResponse"
              :loading="waitForResponse"
            >Войти
            </v-btn>
          </div>
          <a class="register-text" @click="$router.push({name: 'Register'})">
            Регистрация
          </a>
        </v-row>

        <v-snackbar
          v-model="isWrongCredentials"
          class="text-center"
          color="red accent-2"
          absolute
          tile
          bottom
        >
          <span>Неправильный логин или пароль!</span>
        </v-snackbar>
      </form>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: function () {
    return {
      username: '',
      password: '',
      isWrongCredentials: false,
      waitForResponse: false
    }
  },
  methods: {
    login: async function () {
      this.waitForResponse = true;
      await this.$auth.post("login/", {
        username: this.username,
        password: this.password
      }).then(async ({data}) => {
        console.log(data)
        await this.$store.dispatch("authenticated")
        await this.$store.dispatch("initStore")
        await this.$router.push({name: "Description"})
      }).catch(err => {
        this.isWrongCredentials = true;
        console.log(err)
      }).finally(() => {
        this.waitForResponse = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.login-form {
  color: #FFF;
  text-align: center;
}

.login-title {
  font-size: 50px;
  line-height: 80px;
  margin-bottom: 10px;
  font-weight: 600;
}

.login-subtitle {
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 36px;
  font-weight: 500;
}

input {
  width: 300px;
  background: #224957;
  border-radius: 10px;
  height: 45px;
  margin-bottom: 32px;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  border: none;
  padding: 15px;
}

input::placeholder {
  color: #fff;
}

.input-wrapper {
  width: 350px;
}

.submit-button {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  width: 300px;
  height: 45px;
  border: none;
  background-color: #20DF7F !important;
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  color: #FFFFFF;
}

.register-text {
  font-size: 15px;
  padding-top: 10px;
  color: white;
}
</style>
