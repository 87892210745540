<template>
  <v-container fluid class="well-wrapper pa-5">
    <v-row>
      <v-col cols="10">
        <div class="domain-header-text">
          Данные о скважине
        </div>
        <WellCard :id="Number(id)"/>
        <div class="domain-child">
          Образцы
        </div>
        <SampleCard
          :is-recursive="false"
          v-for="sample of samples"
          :key="sample.id"
          :id="sample.id"
        />
      </v-col>
      <v-col cols="2">
        <v-row>
          <AddSampleModalWindow
            :well-id="id"
          ></AddSampleModalWindow>
        </v-row>
        <v-row class="mt-5">
          <WellCreateUpdateModalWindow
            :deposit-id="well.mineral_deposit"
            :update="true"
            :well-id="well.id"
          />
        </v-row>
        <v-row class="mt-5">
          <DeleteConfirmDialog
            :modal-name="'скважину'"
            :on-confirm="deleteWell"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddSampleModalWindow from "@/components/modal/list/SampleCreateUpdateModalWindow.vue";
import {mapGetters, mapMutations} from "vuex";
import wellService from "@/http/service/well-service";
import WellCard from "@/components/domain/WellCard.vue";
import SampleCard from "@/components/domain/SampleCard.vue";
import DeleteConfirmDialog from "@/components/DeleteConfirmDialog.vue";
import WellCreateUpdateModalWindow from "@/components/modal/list/WellCreateUpdateModalWindow.vue";

export default {
  name: "WellView",
  components: {WellCreateUpdateModalWindow, DeleteConfirmDialog, SampleCard, WellCard, AddSampleModalWindow},
  props: ['id'],
  methods: {
    ...mapMutations(['COLLAPSE_WELL']),
    navigateTo(item) {
      this.$router.push(item.link)
    },
    async deleteWell() {
      await wellService.delete(this.well)
    }
  },
  computed: {
    ...mapGetters(['wellSamples', 'getWell']),
    well() {
      return this.getWell(this.id)
    },
    samples() {
      return this.wellSamples(this.well.id)
    }
  },
  watch: {
    id: {
      handler: function () {
        this.COLLAPSE_WELL(this.id)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>