import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from "@/views/Login";
import store from "@/store";
import TectonicView from "@/views/domain/TectonicView";
import DepositView from "@/views/domain/DepositView";
import WellView from "@/views/domain/WellView";
import SampleView from "@/views/domain/SampleView";
import ThinView from "@/views/domain/ThinSectionView";
import ThinSectionView from "@/views/domain/ThinSectionPlotView";
import Description from "@/views/Description.vue";
import Hello from "@/views/Hello.vue";
import About from "@/views/About.vue";
import AnnotationTool from "@/components/annotation/AnnotationTool";
import Register from "@/views/Register.vue";
import AccountNotActivated from "@/views/AccountNotActivated.vue";


Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: {
            name: 'Description'
        },
    },
    {
        path: '/hello',
        name: 'Hello',
        component: Hello,
        meta: {
            layout: 'empty',
            auth_required: false
        }
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            layout: 'empty',
            auth_required: false
        }
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            layout: 'auth',
            auth_required: false
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            layout: 'auth',
            auth_required: false
        }
    },
    {
        path: '/activate',
        name: 'AccountNotActivated',
        component: AccountNotActivated,
        meta: {
            layout: 'empty',
            auth_required: false
        }
    },
    {
        path: '/description',
        name: 'Description',
        component: Description,
        meta: {
            layout: 'empty',
            auth_required: false
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: {
            auth_required: true
        }
    },
    {
        path: '/tectonic/:id',
        name: 'Tectonic',
        component: TectonicView,
        props: true,
        meta: {
            auth_required: true
        }
    },
    {
        path: '/mineral-deposit/:id',
        name: 'Deposit',
        component: DepositView,
        props: true,
        meta: {
            auth_required: true
        }
    },
    {
        path: '/well/:id',
        name: 'Well',
        component: WellView,
        props: true,
        meta: {
            auth_required: true
        }
    },
    {
        path: '/sample/:id',
        name: 'Sample',
        component: SampleView,
        props: true,
        meta: {
            auth_required: true
        }
    },
    {
        path: '/thin-section/:id',
        name: 'Thin Section',
        component: ThinView,
        props: true,
        meta: {
            auth_required: true
        }
    },
    {
        path: '/thin-section-plot/:id',
        name: 'Thin Section Plot',
        component: ThinSectionView,
        props: true,
        meta: {
            auth_required: true
        }
    },
    {
        path: '/annotation/:id',
        name: 'Annotation Tool',
        component: AnnotationTool,
        props: true,
        meta: {
            layout: 'empty',
            auth_required: false
        }
    }
]

const router = new VueRouter({
    routes,
    mode: "history"
})

router.beforeEach(async (to, from, next) => {
    const is_authenticated = await store.dispatch('is_authenticated');

    if (is_authenticated) {
        const is_activated = await store.dispatch('isActivated');

        if (!is_activated && from.name === 'AccountNotActivated') {
            return
        }

        if (!is_activated && to.name !== 'AccountNotActivated') {
            next({name: 'AccountNotActivated'})
        }

        if (is_activated && to.name === 'AccountNotActivated') {
            next({name: 'Home'})
        }
    }

    if (to.name === 'Login' && is_authenticated) {
        next({name: 'Home'})
    }

    if (to.meta.auth_required && !is_authenticated) {
        next({name: 'Hello'})
    }

    next();
})

export default router
