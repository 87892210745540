<template>
<v-container class="video-wrapper fill-height pa-0" fluid>
  <v-row>
    <video
      class="video"
      autoplay="autoplay"
      loop="loop"
      src="@/assets/video/about.mp4"
    ></video>
  </v-row>
</v-container>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped lang="scss">
.video {
  margin: 0;
  padding: 0;
  height: calc(100vh - 85px);
  width: 100%;
  z-index: 1;
}
</style>