<template>
  <v-container fluid class="thin-wrapper pa-5">
    <v-row>
      <v-col cols="10">
        <div class="domain-header-text">
          Данные о шлифе
        </div>
        <ThinSectionCard :id="Number(id)"/>
        <div class="domain-child">
          Участки шлифа
        </div>
        <div class="domain-child__item"
             v-for="thinSectionPlot of plots"
             :key="thinSectionPlot.id"
             @click="navigateTo(thinSectionPlot)"
        >{{ thinSectionPlot.name }}
        </div>
      </v-col>
      <v-col cols="2">
        <v-row>
          <AddThinSectionModalWindow
            :thin-id="id"
          ></AddThinSectionModalWindow>
        </v-row>
        <v-row class="mt-5">
          <ThinSectionCreateUpdateModalWindow
            :sample-id="thinSection.sample"
            :update="true"
            :thin-section-id="thinSection.id"
          />
        </v-row>
        <v-row class="mt-5">
          <DeleteConfirmDialog
            :modal-name="'шлиф'"
            :on-confirm="deleteThinSection"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import thinSectionService from "@/http/service/thin-section-service";
import AddThinSectionModalWindow from "@/components/modal/list/AddThinSectionWindow.vue";
import ThinSectionCard from "@/components/domain/ThinSectionCard.vue";
import DeleteConfirmDialog from "@/components/DeleteConfirmDialog.vue";
import ThinSectionCreateUpdateModalWindow from "@/components/modal/list/ThinSectionCreateUpdateModalWindow.vue";

export default {
  name: "ThinSectionView",
  components: {
    ThinSectionCreateUpdateModalWindow,
    DeleteConfirmDialog,
    ThinSectionCard,
    AddThinSectionModalWindow,
  },
  props: ['id'],
  data() {
    return {
      isDescriptionEditModeEnabled: false,
      newDescription: '',
      successDescriptionChangeAlert: false,
    }
  },
  methods: {
    ...mapMutations(['COLLAPSE_THIN_SECTION']),
    async deleteThinSection() {
      await thinSectionService.delete(this.thinSection)
    },
    navigateTo(item) {
      this.$router.push(item.link)
    },
    setDescriptionMode() {
      this.setNewDescription(this.thinSection.description)
      this.isDescriptionEditModeEnabled = !this.isDescriptionEditModeEnabled
    },
    saveDescription() {
      thinSectionService.updateDescription(
        this.thinSection,
        this.newDescription
      ).then(() => {
        this.thinSection.description = this.newDescription;
        this.isDescriptionEditModeEnabled = false;
        this.successDescriptionChangeAlert = true;
      })
    },
    setNewDescription(description) {
      this.newDescription = description
    }
  },
  computed: {
    ...mapGetters(['thinSectionPlots', 'getThinSection']),
    thinSection() {
      return this.getThinSection(this.id)
    },
    plots() {
      return this.thinSectionPlots(this.thinSection.id)
    },
    description() {
      return this.thinSection.description || 'Описание отсутсвует'
    }
  },
  watch: {
    id: {
      handler: function () {
        this.COLLAPSE_THIN_SECTION(this.id)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">
.save-description-button {
  background-color: rgba(137, 99, 186, .4);
  padding: .5rem 1rem;
  font-weight: bold;
  border-radius: .4rem;
}
</style>