<template>
  <div class="domain">
    <div class="domain__title">
      Тектоническая приуроченность
    </div>
    <div class="domain__text" @click="navigate">
      {{ tectonic.name }}
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "TectonicCard",
  props: {
    id: {
      type: Number,
      required: true
    },
    isRecursive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getTectonic']),
    tectonic() {
      return this.getTectonic(this.id)
    }
  },
  methods: {
    navigate() {
      if (this.$route.name === 'Tectonic') {
        return
      }
      this.$router.push(this.tectonic.link)
    }
  },
}
</script>

<style lang="scss" scoped>

</style>