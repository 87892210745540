<template>
  <v-dialog
      v-model="value"
      max-width="500px">
    <template v-slot:activator="{ on }">
      <button
        v-if="update"
        class="domain-edit-button"
        v-on="on"
      >
        Редактировать
      </button>
      <button
        v-else
        class="domain-add-button"
        v-on="on"
      >Добавить скважину
      </button>
    </template>
    <v-card>
      <WellForm
          :deposit-id="depositId"
          :update="update"
          :well-id="wellId"
          @close-window="close"
      ></WellForm>
    </v-card>
  </v-dialog>
</template>

<script>
import WellForm from "@/components/modal/form/WellCreateUpdateForm.vue";

export default {
  name: "WellCreateUpdateModalWindow",
  components: {WellForm},
  props: {
    depositId: {
      required: true,
      type: [Number, String]
    },
    update: {
      default: false
    },
    // used for update action, otherwise it's undefined
    wellId: {
      required: false,
      type: [Number, String]
    },
  },
  data() {
    return {
      value: false,
    }
  },
  methods: {
    close() {
      this.value = false
    }
  },
}
</script>