import router from "@/router";
import store from "@/store";
import apiInstance from "@/http";

class WellService {
    add(data) {
        return apiInstance.post('well/', data)
            .then(({data}) => {
                store.commit('ADD_WELL', data)
            }).catch(err => console.log(err))
    }

    async delete(well) {
        await apiInstance.delete('well/' + well.id)
        await router.push('/mineral-deposit/' + well.mineral_deposit)
        store.commit('DELETE_WELL', well.id)
    }
}

export default new WellService()