<template>
  <v-form
      class="tectonic-form-container container d-flex flex-column"
      v-model="isValid"
      ref="form"
      lazy-validation
  >
    <v-file-input
        multiple
        small-chips
        prepend-icon="mdi-camera"
        accept="image/png, image/jpeg, image/bmp"
        label="Выберите изображения"
        :rules="[v => v != null || 'Выберите файл']"
        :disabled="isSending"
        v-model="images"
        @change="handleChosenImages($event)"
    ></v-file-input>
    <v-container>
      <v-row
          v-for="imgWrapper of chosenImages"
          :key="imgWrapper.name"
          class="align-center"
      >
        <v-col cols="auto" style="width: 110px;">
          <v-text-field
              hide-details
              label="Угол поворота"
              hint="Угол поворота"
              v-model="imgWrapper.grade"
              :rules="[val => !!val || 'Required.']"
              :error="imgWrapper.grade === ''"
          ></v-text-field>
        </v-col>
        <v-col cols="auto" style="max-width: 300px; min-width: 100px">
          <span>{{ imgWrapper.name }}</span>
        </v-col>

        <v-col cols="auto" style="max-width: 300px; min-width: 100px">
          <v-progress-circular
              v-if="imgWrapper.isSending"
              indeterminate
              color="amber"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>

    <v-btn
        @click.prevent="addImages"
        :disabled="isSending"
    >Добавить
    </v-btn>
  </v-form>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "ThinSectionPlotImageForm",
  props: ['thinSectionId'],
  data() {
    return {
      isValid: true,
      value: false,
      images: null,
      isSending: false,
      currentRequests: [],
      chosenImages: []
    }
  },
  methods: {
    ...mapMutations(['ADD_THIN_SECTION_PLOT_CROSSED_IMAGE']),
    async addImages() {
      for (const image of this.chosenImages) {
        if (image.grade === '') {
          return
        }
      }
      this.isSending = true
      for (const image of this.chosenImages) {
        this.sendResponse(image)
      }
      Promise.all(this.currentRequests).then(() => {
        console.log('completed')
        this.currentRequests = []
        this.isSending = false
      }).catch(() => {
        this.isSending = false
      })
      console.log('started', this.images)
    },
    sendResponse(image) {
      console.log(image);
      let formData = new FormData()

      formData.append('thin_section_plot', this.$route.params.id)
      formData.append('image.image', image.image)
      formData.append('grade', image.grade)

      const request = this.$api.post('/crossed-image/', formData)
      this.currentRequests.push(request)
      image.isSending = true
      request.then(async ({data}) => {
        image.isSending = false
        this.chosenImages = this.chosenImages.filter(img => {
          return img !== image
        })
        this.images = this.images.filter(img => {
          return img !== image.image
        })
        console.log(data)
        this.ADD_THIN_SECTION_PLOT_CROSSED_IMAGE(data)
        this.$emit('add', data)
      }).catch(err => {
        console.error(err)
        image.isSending = false
      })
    },
    handleChosenImages(images) {
      this.chosenImages = images.map(img => {
        return {
          image: img,
          name: img.name,
          grade: this.getGradeFrom(img),
          isSending: false
        }
      })
    },
    getGradeFrom(img) {
      const regex = /\d+/g
      const matches = img.name.match(regex)
      return matches != null ? matches[0] : ''
    },
  }
}
</script>

<style scoped>

</style>