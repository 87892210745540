import router from "@/router";
import store from "@/store";
import apiInstance from "@/http";

class ThinSectionService {
    add(data) {
        return apiInstance.post('thin-section/', data)
            .then(({data}) => {
                store.commit('ADD_THIN_SECTION', data)
            }).catch(err => console.log(err))
    }

    updateDescription(thinSection, newDescription) {
        return apiInstance.patch('thin-section/' + thinSection.id + '/', {
            description: newDescription
        })
    }

    async delete(thinSection) {
        await apiInstance.delete('thin-section/' + thinSection.id)
        await router.push('/sample/' + thinSection.sample)
        store.commit('DELETE_THIN_SECTION', thinSection.id)
    }
}

export default new ThinSectionService()