<template>
    <v-row>
      <v-col class="py-0">
        <v-checkbox v-model="parameters.is_include" :label="parameters.name" @click="changeClass" :disabled="isClassification"></v-checkbox>
      </v-col>
      <v-col class="py-0" sm="5">
        <v-checkbox v-model="parameters.is_instance" :disabled="isClassification"></v-checkbox>
      </v-col>
    </v-row>
</template>

<script>
export default {
  name: "InputParameterModelsItem",
  props:{
    parameters: {
      type: Object,
      default() {
        return {}
      }
    },
    isClassification: {
      type: Boolean,
      default() {
        return {}
      }
    }
  },
  methods:{
    changeClass(){
      this.parameters.is_instance = this.parameters.is_include === true;
    }
  },
}
</script>

<style scoped>

</style>