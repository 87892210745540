<template>
  <div>
    <SampleCard v-if="isRecursive" :id="thinSection.sample"/>
    <v-row v-if="isRecursive">
      <v-col cols="9" class="pb-0">
        <div class="domain__title">
          Шлиф
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <div class="domain">
          <div class="domain__text" @click="navigate">
            {{ thinSection.name }}
          </div>
          <div class="domain__description">
            {{ thinSection.description }}
          </div>
        </div>
      </v-col>
      <v-col cols="2" v-if="thinSection.image">
        <img v-if="thinSection.image"
             v-img="{src: thinSection.image.origin}"
             :src="thinSection.image.origin"
             style="width: 100%"
             alt=""
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SampleCard from "@/components/domain/SampleCard.vue";

export default {
  name: "ThinSectionCard",
  components: {SampleCard},
  props: {
    id: {
      type: Number,
      required: true
    },
    isRecursive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getThinSection']),
    thinSection() {
      return this.getThinSection(this.id)
    },
    isThinSectionPlotRoute() {
      return this.$route.name === 'Thin Section'
    }
  },
  methods: {
    navigate() {
      if (this.isThinSectionPlotRoute) {
        return
      }
      this.$router.push(this.thinSection.link)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>