<template>
  <v-container fluid class="align-start">
    <v-row class="pa-5">
      <v-col class="thin-section">
        <v-row class="thin-section__title">
          Участок шлифа
          {{ thinSectionPlot.analyzes }}
        </v-row>
        <v-row class="thin-section__name">
          {{ thinSectionPlot.name }}
        </v-row>
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            <AddThinSectionImageModalWindow
                :thin-id="thinSectionPlot.id"
            ></AddThinSectionImageModalWindow>
          </v-col>
          <v-col>
            <button
                class="analyze-run-button"
                @click="inputParameters"
            >
              Запуск полного анализа
            </button>
          </v-col>
          <v-col>
            <DeleteConfirmDialog :on-confirm="deleteEntity" modal-name="участок шлифа"/>
          </v-col>
        </v-row>
        <v-row>
          <v-btn-toggle
              v-model="tab"
              tile
              color="deep-purple accent-3"
              mandatory
              group
          >
            <v-btn :value="tabValues.images">
              Исходные изображения
            </v-btn>
            <v-btn :value="tabValues.results">
              Результаты анализа
            </v-btn>
          </v-btn-toggle>
          <v-col></v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-5 main-section">
      <ImagesTab
          v-if="tab === tabValues.images"
          :crossed-images="thinSectionPlot.crossed_images"
          :parallel-image="thinSectionPlot.parallel_image"
      />
      <ResultsTab
          v-if="tab === tabValues.results"
          :thin-section-plot="thinSectionPlot"
          :rotate-action="thinSectionPlot.rotate_action"
          :predict-action="thinSectionPlot.predict_action"
          :granulometric-analyze-action="thinSectionPlot.granulometric_analyze_action"
      />
    </v-row>
    <v-snackbar
        v-if="hasAnalyze"
        :value="thinSectionPlot.analyze"
        content-class="snack"
        :timeout="-1"
        :min-width="0"
        color="#F0F0F0"
        right
    >
      <v-row class="justify-center align-center">
        <v-col cols="auto">
          <div v-if="isAnalyzeInProgress || isAnalyzePending" class="lds-dual-ring"></div>
          <v-icon v-if="isAnalyzeCompleted" color="green">
            mdi-check-bold
          </v-icon>
          <v-icon v-if="isAnalyzeError" color="red">
            mdi-close-thick
          </v-icon>
        </v-col>
        <v-col cols="auto">Статус операции: {{ analyzeStatus }}</v-col>
      </v-row>
    </v-snackbar>

    <RerunAnalyzeConfirmDialog
        ref="rerunConfirmDialog"
        :on-confirm="runFullAnalyze"
    />
    <InputParameterModelsDialog
        ref="inputParameterModelsDialog"
        :on-confirm="checkForAnalyzeRun"
        v-bind:parameters="parameters"
        @check-all="checkAllClasses"
        @uncheck-all="uncheckAllClasses"
    />
  </v-container>
</template>
<script>
import {mapGetters, mapMutations} from "vuex";
import thinSectionPlotService from "@/http/service/thin-section-plot-service";
import AddThinSectionImageModalWindow from "@/components/modal/list/AddThinSectionPlotImageWindow.vue";
import DeleteConfirmDialog from "@/components/DeleteConfirmDialog.vue";
import ImagesTab from "@/views/thin-section-plot-tabs/ImagesTab.vue";
import ResultsTab from "@/views/thin-section-plot-tabs/ResultsTab.vue";
import analyzeService from "@/http/service/analyze-service";
import RerunAnalyzeConfirmDialog from "@/components/RerunAnalyzeConfirmDialog.vue";
import InputParameterModelsDialog from "@/components/InputParameterModelsDialog.vue";

export default {
  name: "ThinSectionPlotView",
  components: {
    InputParameterModelsDialog,
    RerunAnalyzeConfirmDialog,
    ResultsTab,
    ImagesTab,
    DeleteConfirmDialog,
    AddThinSectionImageModalWindow
  },
  props: ['id'],
  data() {
    return {
      tab: 'images',
      tabValues: {
        images: 'images',
        results: 'results'
      },
      report: {
        gran_analyses: [],
        image_analyze_crs: [],
        image_analyze_pls: []
      },
      classes_labels: ['Поры', 'Границы', 'Кварц', 'Слюда', 'Рудные минералы',
        'Глина', 'Карбонаты', 'Глинистый цемент', 'Неопределенные области',
        'Спарит', 'Микрит', 'Остатки организмов'],

      parameters: {
        isClassification: false,
        classes: []
      }
    }
  },
  methods: {
    ...mapMutations(['COLLAPSE_THIN_SECTION_PLOT', 'ADD_ANALYZE']),
    async deleteEntity() {
      await thinSectionPlotService.delete(this.thinSectionPlot)
    },

    inputParameters() {
      this.$refs.inputParameterModelsDialog.dialog = true
    },

    checkForAnalyzeRun() {
      if (this.hasAnalyze && (this.isAnalyzeInProgress || this.isAnalyzePending)) {
        this.$refs.rerunConfirmDialog.dialog = true
        return
      }
      this.runFullAnalyze()
    },
    runFullAnalyze() {
      analyzeService.runFullAnalyze(this.thinSectionPlot.id, this.parameters)
          .then(({data}) => {
            this.ADD_ANALYZE({
              thinSectionPlotId: this.thinSectionPlot.id,
              analyze: data
            })
          })
          .catch(err => {
            console.log(err)
          })
    },
    uncheckAllClasses() {
      this.parameters.classes = []
      for (let i = 0; i < this.classes_labels.length; i++) {
        if (i < 2 || i === 8){
          this.parameters.classes.push({
            name: this.classes_labels[i],
            is_include: true,
            is_instance: true
          })
          continue;
        }
        this.parameters.classes.push({
          name: this.classes_labels[i],
          is_include: false,
          is_instance: false
        })
      }
      console.log('Parameters', this.parameters.classes);
    },
    checkAllClasses() {
      this.parameters.classes = []
      for (let i = 0; i < this.classes_labels.length; i++) {
        this.parameters.classes.push({
          name: this.classes_labels[i],
          is_include: true,
          is_instance: true
        })
      }
      console.log('Parameters', this.parameters.classes);
    }
  },
  computed: {
    ...mapGetters(['getThinSectionPlot']),
    thinSectionPlot() {
      this.COLLAPSE_THIN_SECTION_PLOT(this.id)
      return this.getThinSectionPlot(this.id)
    },
    hasSourceImages() {
      return this.thinSectionPlot.crossed_images.length > 0 || this.thinSectionPlot.parallel_image != null
    },
    hasAnalyzeResults() {
      return this.thinSectionPlot.rotate_action != null ||
          this.thinSectionPlot.predict_action != null ||
          this.thinSectionPlot.granulometric_analyze_action != null
    },
    canRunRotate() {
      return !this.hasAnalyze || this.isAnalyzeCompleted || this.isAnalyzeError
          || this.thinSectionPlot.crossed_images.length > 0 && this.thinSectionPlot.parallel_image !== null
    },
    hasAnalyze() {
      return this.thinSectionPlot.analyze !== null
    },
    isAnalyzeCompleted() {
      return this.thinSectionPlot.analyze.status === 'completed'
    },
    isAnalyzeInProgress() {
      return this.thinSectionPlot.analyze.status === 'in progress'
    },
    isAnalyzePending() {
      return this.thinSectionPlot.analyze.status === 'pending'
    },
    isAnalyzeError() {
      return this.thinSectionPlot.analyze.status === 'error'
    },
    analyzeStatus() {
      if (this.isAnalyzeCompleted) {
        return 'Успешно рассчитано'
      } else if (this.isAnalyzeError) {
        return 'Ошибка'
      } else {
        return 'Рассчет выполняется'
      }
    }
  },
  watch: {
    id: {
      handler: function () {
        this.COLLAPSE_THIN_SECTION_PLOT(this.id)
      },
      immediate: true
    }
  },
  mounted() {
    this.uncheckAllClasses();
  }
}
</script>

<style scoped lang="scss">

</style>

<style scoped lang="scss">

.thin-section {
  &__title {
    font-size: 25px;
  }

  &__name {
    font-size: 40px;
  }
}

.analyze-run-button {
  background-color: #20DF7F;
  font-weight: 600;
  padding: 1.5rem;
  border-radius: .5rem;
  width: 100%;
  font-size: 15px;
}

.tab-button {

}

.main-section {
  overflow-y: auto;
}

.lds-dual-ring {
  display: inline-block;
  width: 36px;
  height: 36px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 20px;
  height: 20px;
  margin: 8px;
  border-radius: 50%;
  border: 3px solid blue;
  border-color: blue transparent blue transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

<style>
.snack {
  color: black !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}
</style>
