<template>
  <div>
    <WellCard v-if="isRecursive" :id="sample.well"/>
    <v-row v-if="isRecursive">
      <v-col cols="9" class="pb-0">
        <div class="domain__title">
          Образец
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="9">
        <div class="domain">
          <div class="domain__text" @click="navigate">
            {{ sample.name }}
          </div>
          <div class="domain__description">
            {{ sample.description }}
          </div>
        </div>
      </v-col>
      <v-col cols="2">
        <img v-if="sample.image"
             v-img="{src: sample.image.origin}"
             :src="sample.image.origin"
             style="width: 100%; height: 100%"
             alt=""
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import WellCard from "@/components/domain/WellCard.vue";

export default {
  name: "SampleCard",
  components: {WellCard},
  props: {
    id: {
      type: Number,
      required: true
    },
    isRecursive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getSample']),
    sample() {
      return this.getSample(this.id)
    },
    isSampleRoute() {
      return this.$route.name === 'Sample'
    }
  },
  methods: {
    navigate() {
      if (this.isSampleRoute) {
        return
      }
      this.$router.push(this.sample.link)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>