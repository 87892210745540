import apiInstance from "@/http";

class AnalyzeService {
    runFullAnalyze(thinSectionPlotId, parameters) {
        return apiInstance.post('/analyze/', {
            type: 'full',
            thin_section_plot: thinSectionPlotId,
            parameters: parameters
        })
    }

    // annotationSave(thinSectionPlotId, new_mask) {
    //     console.log(thinSectionPlotId);
    //     return apiInstance.post('/annotation/', {
    //         thin_section_plot: thinSectionPlotId,
    //         mask: new_mask
    //     })
    // }

    runRotateAction(thinSectionPlotId) {
        return apiInstance.post('/analyze/', {
            type: 'rotate',
            thin_section_plot: thinSectionPlotId
        })
    }

    runPredictAction(thinSectionPlotId) {
        return apiInstance.post('/analyze/', {
            type: 'predict',
            thin_section_plot: thinSectionPlotId
        })
    }

    runGranulometricAnalyze(thinSectionPlotId) {
        return apiInstance.post('/analyze/', {
            type: 'granulometric analyze',
            thin_section_plot: thinSectionPlotId
        })
    }
}

export default new AnalyzeService();