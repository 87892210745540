<template>
  <v-container fluid class="sample-wrapper pa-5">
    <v-row>
      <v-col cols="10">
        <div class="domain-header-text">
          Данные об образце
        </div>
        <SampleCard :id="Number(id)"/>
        <div class="domain-child">
          Шлифы
        </div>
        <div class="domain-child__item"
             v-for="thinSection of thinSections"
             :key="thinSection.id"
             @click="navigateTo(thinSection)"
        >{{ thinSection.name }}
        </div>
      </v-col>
      <v-col cols="2">
        <v-row>
          <AddThinModalWindow
            :sample-id="id"
          ></AddThinModalWindow>
        </v-row>
        <v-row class="mt-5">
          <SampleCreateUpdateModalWindow
            :well-id="sample.well"
            :update="true"
            :sample-id="id"
          />
        </v-row>
        <v-row class="mt-5">
          <DeleteConfirmDialog
            :modal-name="'образец'"
            :on-confirm="deleteSample"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AddThinModalWindow from "@/components/modal/list/ThinSectionCreateUpdateModalWindow.vue";
import {mapGetters, mapMutations} from "vuex";
import sampleService from "@/http/service/sample-service";
import SampleCard from "@/components/domain/SampleCard.vue";
import DeleteConfirmDialog from "@/components/DeleteConfirmDialog.vue";
import SampleCreateUpdateModalWindow from "@/components/modal/list/SampleCreateUpdateModalWindow.vue";

export default {
  name: "SampleView",
  components: {
    SampleCreateUpdateModalWindow,
    DeleteConfirmDialog,
    AddThinModalWindow,
    SampleCard,
  },
  props: ['id'],
  data() {
    return {
      isDescriptionEditModeEnabled: false,
      newDescription: '',
      successDescriptionChangeAlert: false,
    }
  },
  methods: {
    ...mapMutations(['COLLAPSE_SAMPLE']),
    async deleteSample() {
      await sampleService.delete(this.sample)
    },
    navigateTo(thin) {
      this.$router.push(thin.link)
    },
    setDescriptionMode() {
      this.setNewDescription(this.sample.description)
      this.isDescriptionEditModeEnabled = !this.isDescriptionEditModeEnabled
    },
    saveDescription() {
      sampleService.updateDescription(
        this.sample,
        this.newDescription
      ).then(() => {
        this.sample.description = this.newDescription;
        this.isDescriptionEditModeEnabled = false;
        this.successDescriptionChangeAlert = true;
      })
    },
    setNewDescription(description) {
      this.newDescription = description
    }
  },
  computed: {
    ...mapGetters(['sampleThinSections', 'getSample']),
    sample() {
      this.COLLAPSE_SAMPLE(this.id)
      return this.getSample(this.id)
    },
    thinSections() {
      return this.sampleThinSections(this.sample.id)
    },
    description() {
      return this.sample.description || 'Описание отсутсвует'
    }
  },
  watch: {
    id: {
      handler: function () {
        this.COLLAPSE_SAMPLE(this.id)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

.save-description-button {
  background-color: rgba(137, 99, 186, .4);
  padding: .5rem 1rem;
  font-weight: bold;
  border-radius: .4rem;
}

</style>