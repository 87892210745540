<template>
  <v-dialog
      v-model="value"
      max-width="500px">
    <template v-slot:activator="{ on }">
      <button class="domain-add-button" v-on="on">Добавить участок шлифа</button>
    </template>
    <v-card>
      <ThinSectionPlotForm
          :thin-id="thinId"
          @add="added($event)"
      ></ThinSectionPlotForm>
    </v-card>
  </v-dialog>
</template>

<script>
import ThinSectionPlotForm from "@/components/modal/form/ThinSectionPlotForm";

export default {
  name: "AddThinSectionModalWindow",
  components: {ThinSectionPlotForm},
  props: ['thinId'],
  data() {
    return {
      value: false,
    }
  },
  methods: {
    added(data) {
      this.$emit("add", data)
      this.value = false
    }
  },
}
</script>