class EntityNormalizer {
    normalizeTectonic(tectonic) {
        return {
            ...tectonic,
            show: false,
            link: '/tectonic/' + tectonic.id,
        }
    }
    normalizeDeposit(deposit) {
        return {
            ...deposit,
            show: false,
            link: '/mineral-deposit/' + deposit.id,
        }
    }
    normalizeWell(well) {
        return {
            ...well,
            show: false,
            link: '/well/' + well.id,
        }
    }
    normalizeSample(sample) {
        return {
            ...sample,
            show: false,
            link: '/sample/' + sample.id,
        }
    }
    normalizeThinSection(thinSection) {
        return {
            ...thinSection,
            show: false,
            link: '/thin-section/' + thinSection.id,
        }
    }
    normalizeThinSectionPlot(thinSectionPlot) {
        return {
            ...thinSectionPlot,
            show: false,
            link: '/thin-section-plot/' + thinSectionPlot.id,
        }
    }
}

export default new EntityNormalizer()