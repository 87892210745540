<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="400"
  >
    <v-card>
      <v-card-title class="text-h5">
        Подтверждение перезапуска <br> анализа
      </v-card-title>
      <v-card-text>
        У вас уже имеется запущенный анализ. Вы действительно хотите его перезапустить?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green darken-1"
          text
          @click="dialog = false"
        >
          Отмена
        </v-btn>
        <v-btn
          color="red"
          text
          @click="confirmed"
        >
          Перезапустить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "RerunAnalyzeConfirmDialog",
  props: {
    onConfirm: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      dialog: null
    }
  },
  methods: {
    async confirmed() {
      await this.onConfirm()
      this.dialog = false
    }
  }
}
</script>

<style scoped lang="scss">

</style>