import axios from "axios";
import {BASE_URL, HTTP} from "./base"
import store from "@/store";
import router from "@/router";

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const authHttpClient = axios.create({
    baseURL: `${HTTP}://${BASE_URL}/user`,
    withCredentials: true
})

authHttpClient.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const {config, response} = err;

        if (response.status !== 401) {
            return Promise.reject(err);
        }

        if (response.config.url === 'refresh/') {
            await store.dispatch('logout')
            await router.push({name: 'Login'})
            return Promise.reject(err);
        }

        await authHttpClient.post("refresh/")
        return authHttpClient(config);
    }
)

export default authHttpClient;
