<template>
  <v-form
      class="tectonic-form-container container d-flex flex-column"
      v-model="isValid"
      ref="form"
      lazy-validation
      @submit.prevent="addThinSectionPlot"
  >
    <v-text-field
        class="mb-2"
        label="Введите название участка шлифа"
        v-model="name"
        :rules="[v => v != null && v.length > 0 || 'Введите название']"
        autofocus
    ></v-text-field>
    <v-btn
        @click="addThinSectionPlot"
        :disabled="isSending"
    >Добавить
    </v-btn>
  </v-form>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "ThinSectionPlotForm",
  props: ['thinId'],
  data() {
    return {
      isValid: true,
      name: '',
      isSending: false
    }
  },
  methods: {
    ...mapMutations(['ADD_THIN_SECTION_PLOT']),
    async addThinSectionPlot() {
      await this.$refs.form.validate()

      if (!this.isValid) {
        return
      }

      let formData = new FormData()

      formData.append('name', this.name)
      formData.append('thin_section', this.thinId)

      this.isSending = true

      await this.$api.post('thin-section-plot/',
          formData
      ).then(async ({data}) => {
        this.isSending = false
        console.log(data)
        this.ADD_THIN_SECTION_PLOT(data)
        this.$router.push({name: 'Thin Section Plot', params: {id: data.id}})
        await this.$refs.form.reset()
        this.value = false
        this.$emit('add', data)
      }).catch(err => {
        console.error(err)
        this.isSending = false
      })
    }
  }
}
</script>

<style scoped>

</style>