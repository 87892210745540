<template>
  <v-form
    class="tectonic-form-container container d-flex flex-column"
    v-model="isValid"
    ref="form"
    lazy-validation
  >
    <v-file-input
      prepend-icon="mdi-camera"
      accept="image/png, image/jpeg, image/bmp"
      label="Выберите изображение"
      :rules="[v => v != null || 'Выберите файл', hasScaleBar || 'Изображение не содержит scalebar']"
      :disabled="isSending"
      v-model="image"
    ></v-file-input>

    <v-text-field
      class="mb-2"
      label="Введите scale"
      v-model="scale"
      :rules="[v => v != null && v.length > 0 || 'Это поле не должно быть пустым']"
      autofocus
    ></v-text-field>
    <v-btn
      @click.prevent="addImage"
      :disabled="isSending"
    >Добавить
    </v-btn>
    <v-snackbar
      :timeout="2000"
      :value="!hasScaleBar"
      color="red darken-1"
      text
    >
      The image doesn't have a scale bar!
    </v-snackbar>
  </v-form>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "ThinSectionPlotParallelImageForm",
  props: ['thinSectionId'],
  data() {
    return {
      isValid: true,
      value: false,
      image: null,
      isSending: false,
      scale: null,
      hasScaleBar: true
    }
  },
  methods: {
    ...mapMutations(['ADD_THIN_SECTION_PLOT_PARALLEL_IMAGE']),
    async addImage() {
      this.hasScaleBar = true
      await this.$refs.form.validate()
      if (!this.isValid) {
        return
      }

      let formData = new FormData()
      formData.append('thin_section_plot', this.$route.params.id)
      formData.append('image.image', this.image)
      formData.append('scale', this.scale.replace(',', '.'))
      this.isSending = true

      await this.$api.post('/parallel-image/', formData).then(async ({data}) => {
        console.log(data)
        this.ADD_THIN_SECTION_PLOT_PARALLEL_IMAGE(data)
        this.$emit('add', data)
        this.$refs.form.reset()
      }).catch(err => {
        this.hasScaleBar = false
        console.log(err)
      })
      this.isSending = false
    },
  }
}
</script>

<style scoped>

</style>