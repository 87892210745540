<template>
  <v-form
      class="tectonic-form-container container d-flex flex-column"
      v-model="isValid"
      ref="form"
      @submit.prevent="addWell"
  >
    <v-text-field
        label="Введите название скважины"
        v-model="name"
        :rules="rules"
        autofocus
    ></v-text-field>
    <v-btn @click="addWell">
      {{ buttonText }}
    </v-btn>
  </v-form>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "WellCreateUpdateForm",
  props: {
    depositId: {
      required: true,
      type: [String, Number]
    },
    update: {
      default: false
    },
    // used for update action, otherwise it's undefined
    wellId: {
      required: false,
      type: [String, Number]
    }
  },
  data() {
    return {
      isValid: false,
      rules: [v => v != null && v.length > 0 || 'Название не должно быть пустым'],
      name: '',
    }
  },
  created() {
    this.name = this.well?.name || ''
  },
  computed: {
    ...mapGetters(['getWell']),
    well() {
      return this.getWell(this.wellId)
    },
    buttonText() {
      return this.update ? 'Обновить' : 'Добавить'
    }
  },
  watch: {
    $props: {
      immediate: true,
      handler() {
        this.validateProps();
      }
    }
  },
  methods: {
    ...mapMutations(['ADD_WELL', 'UPDATE_WELL']),
    validateProps() {
      if (this.update && !this.wellId) {
        throw new Error("The 'well-id' props should be passed if 'update === true'")
      }
    },
    async addWell() {
      await this.$refs.form.validate()
      if (!this.isValid) {
        return
      }

      const data = {
        name: this.name,
        mineral_deposit: this.depositId,
        depth: 0,
        photo_kern: null
      }

      let response = null;
      if (this.update) {
        response = this.$api.patch(
            `well/${this.wellId}/`,
            data
        )
      } else {
        response = this.$api.post(
            'well/',
            data
        )
      }

      response.then(({data}) => {
        if (this.update) {
          this.UPDATE_WELL(data)
        } else {
          this.ADD_WELL(data)
          this.$router.push({name: 'Well', params: {id: data.id}})
        }
        this.$refs.form.reset()
        this.value = false
        this.$emit('close-window')
      })
    }
  }
}
</script>

<style scoped>

</style>