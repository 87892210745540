<template>
  <v-container class="login fill-height">
    <v-row class="justify-center align-center">
      <v-col cols="6">
        <p class="register-information">
          Уважаемый пользователь, <br>
          вы хотите зарегистрироваться в демо-версии <br>
          программы "Цифровой шлиф". <br>
          Поскольку доступ предоставляется только <br>
          временно, данные могут быть утеряны. <br>
        </p>
      </v-col>
      <v-col cols="4">
        <v-form
          v-model="isValid"
          class="register-form"
          ref="form"
        >
          <v-row class="justify-center flex-column align-center">
            <div class="input-wrapper">
              <v-text-field
                solo
                dark
                class="login-input-field"
                type="text"
                name="login"
                placeholder="Имя"
                background-color="rgba(255, 255, 255, 0.3)"
                v-model="user.firstName"
                :rules="rules.emptyRule"
              />
              <v-text-field
                solo
                dark
                class="login-input-field"
                type="text"
                name="login"
                placeholder="Фамилия"
                background-color="rgba(255, 255, 255, 0.3)"
                v-model="user.lastName"
                :rules="rules.emptyRule"
              />
              <v-text-field
                solo
                dark
                class="login-input-field"
                type="text"
                name="login"
                placeholder="Почта"
                background-color="rgba(255, 255, 255, 0.3)"
                v-model="user.email"
                :rules="rules.emailRules"
              />
              <v-text-field
                solo
                dark
                class="login-input-field"
                type="password"
                name="password"
                placeholder="Пароль"
                color="white"
                background-color="rgba(255, 255, 255, 0.3)"
                v-model="user.password"
                :rules="rules.emptyRule"
              />
              <button
                class="submit-button"
                @click.prevent="register"
                :disabled="waitForResponse"
                :loading="waitForResponse"
              >Зарегестрироваться
              </button>
            </div>
            <a class="register-text" @click="$router.push({name: 'Login'})">
              Авторизация
            </a>
          </v-row>

          <v-snackbar
            v-model="isUserAlreadyExists"
            class="text-center"
            color="red accent-2"
            absolute
            tile
            bottom
          >
            <span>Пользователь с такой почтой уже зарегестрирован</span>
          </v-snackbar>

          <v-snackbar
            :timeout="2000"
            v-model="isSuccess"
            class="text-center"
            color="success"
            absolute
            tile
            bottom
          >
            <span>Аккаунт был успешно создан!</span>
          </v-snackbar>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Register",
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
      },
      isUserAlreadyExists: false,
      isSuccess: false,
      waitForResponse: false,
      rules: {
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        emptyRule: [
          v => !!v || 'This field shouldn\'t be empty!'
        ],
      },
      isValid: true
    }
  },
  methods: {
    register: async function () {
      this.$refs.form.validate()
      if (!this.isValid) {
        return
      }
      this.waitForResponse = true;
      await this.$auth.post("register/", {
        first_name: this.user.firstName,
        last_name: this.user.lastName,
        email: this.user.email,
        password: this.user.password
      }).then(() => {
        this.isSuccess = true
        // this.user = {
        //   firstName: '',
        //   lastName: '',
        //   email: '',
        //   password: '',
        // }
        this.$refs.form.reset()
      }).catch(err => {
        if (err.response.status === 409) {
          this.isUserAlreadyExists = true
        }
      }).finally(() => {
        this.waitForResponse = false;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-input-field {
  border-radius: 10px;
  font-size: 20px;
  line-height: 20px;
  border: none;
  width: 350px;
}

.submit-button {
  width: 350px;
  height: 45px;
  border: none;
  background: #20DF7F;
  border-radius: 12px;
  font-size: 20px;
  line-height: 20px;
  text-transform: capitalize;
  color: white !important;
  font-weight: bold;
}

.register-information {
  color: white;
  font-size: 25px;
  text-align: center;
}

.register-text {
  font-size: 15px;
  padding-top: 10px;
  color: white;
}
</style>