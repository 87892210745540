import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import authHttpClient from "@/http/auth";
import http from "@/http";
import vuetify from '@/plugins/vuetify'
import VueImg from 'v-img';

Vue.use(VueImg);

Vue.config.productionTip = false;
Vue.prototype.$auth = authHttpClient;
Vue.prototype.$api = http;

store.dispatch('initStore').finally(() => {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app')
})