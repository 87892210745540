<template>
  <vue-extend-layouts/>
</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'
import thinSectionPlotService from "@/http/service/thin-section-plot-service";
import {BASE_URL, HTTP} from "@/http/base"

export default {
  name: 'App',
  components: {VueExtendLayouts},
  data() {
    return {
      socketConnection: null
    }
  },
  mounted() {
    this.establishSocketConnection()
  },
  methods: {
    establishSocketConnection() {
      if (this.socketConnection != null) {
        this.socketConnection.close()
      }
      const WS_PROTOCOL = HTTP === 'https' ? 'wss' : 'ws';
      this.socketConnection = new WebSocket(`${WS_PROTOCOL}://${BASE_URL}/plot-analyze/`)
      this.socketConnection.onmessage = function (event) {
        const message = JSON.parse(event.data)
        console.log(message)
        thinSectionPlotService.updateThinSectionPlot(message.thin_section_plot_id)
        console.log('updated', message.thin_section_plot_id)
      }.bind(this)
      this.socketConnection.onopen = function (event) {
        console.log(event)
        console.log("websocket connection established...")
      }
      this.socketConnection.onerror = function (event) {
        console.log(event)
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.v-application {
  font-family: 'Montserrat', sans-serif !important;
}

</style>
