import router from "@/router";
import store from "@/store";
import apiInstance from "@/http";

class ThinSectionPlotService {
    add(data) {
        return apiInstance.post('thin-section-plot/', data)
            .then(({data}) => {
                store.commit('ADD_THIN_SECTION_PLOT', data)
            }).catch(err => console.log(err))
    }

    async delete(thinSectionPlot) {
        await apiInstance.delete('thin-section-plot/' + thinSectionPlot.id)
        await router.push('/thin-section/' + thinSectionPlot.thin_section)
        store.commit('DELETE_THIN_SECTION_PLOT', thinSectionPlot.id)
    }

    async updateThinSectionPlot(id) {
        await apiInstance.get('thin-section-plot/' + id)
            .then(({data}) => {
                console.log(data)
                store.commit('UPDATE_THIN_SECTION_PLOT', data)
            }).catch(err => console.log(err))
    }

    async downloadGranulometricAnalyzeResults(id, fileName) {
        await apiInstance({
            url: 'thin-section-plot/' + id + '/download-results',
            method: 'GET',
            responseType: 'blob'
        }).then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
        });
    }
}

export default new ThinSectionPlotService()