<template>
  <v-dialog
      v-model="value"
      max-width="500px">
    <template v-slot:activator="{ on }">
      <button class="domain-add-button" v-on="on">Добавить изображения</button>
    </template>
    <v-card>
      <v-btn-toggle
          v-model="imageType"
          tile
          color="deep-purple accent-3"
          group
          mandatory
      >
        <v-btn value="crossed">
          Скрещенные николи
        </v-btn>
        <v-btn value="parallel">
          Параллельные николи
        </v-btn>
      </v-btn-toggle>
      <ThinSectionPlotImageForm
          v-if="imageType === 'crossed'"
          :thin-section-id="thinSectionId"
          @add="added($event)"
      ></ThinSectionPlotImageForm>
      <ThinSectionPlotParallelImageForm
          v-else
          :thin-section-id="thinSectionId"
          @add="added($event)"
      ></ThinSectionPlotParallelImageForm>
    </v-card>
  </v-dialog>
</template>

<script>
import ThinSectionPlotImageForm from "@/components/modal/form/ThinSectionPlotImageForm";
import ThinSectionPlotParallelImageForm from "@/components/modal/form/ThinSectionPlotParallelImageForm";

export default {
  name: "AddThinSectionImageModalWindow",
  components: {ThinSectionPlotParallelImageForm, ThinSectionPlotImageForm},
  props: ['thinSectionId'],
  data() {
    return {
      value: false,
      imageType: 'crossed'
    }
  },
  methods: {
    added(data) {
      this.$emit("add", data)
    }
  },
}
</script>
