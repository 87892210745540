import router from "@/router";
import store from "@/store";
import apiInstance from "@/http";

class DepositService {
    add(data) {
        return apiInstance.post('mineral-deposit/', data)
            .then(({data}) => {
                store.commit('ADD_DEPOSIT', data)
                return data
            }).catch(err => console.log(err))
    }

    update(data, depositId) {
        return apiInstance.patch(`mineral-deposit/${depositId}/`, data)
            .then(({data}) => {
                store.commit('UPDATE_DEPOSIT', data)
            }).catch(err => console.log(err))
    }

    async delete(deposit) {
        await apiInstance.delete('mineral-deposit/' + deposit.id)
        await router.push('/tectonic/' + deposit.tectonic_confinement)
        store.commit('DELETE_DEPOSIT', deposit.id)
    }
}

export default new DepositService()