<template>
  <v-container class="fill-height wrapper" fluid>
    <v-row>
      <v-col>
        <v-row class="justify-center align-center">
          <v-col cols="auto">
            <img
              class="thin-logo"
              src="thin-logo-dark.svg"
              alt="Thin Section Logo"
            >
          </v-col>
          <v-col cols="auto" class="title-text">
            Цифровой шлиф
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <button class="about-button" @click="goAbout">
            О приложении
          </button>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Hello",
  methods: {
    goAbout() {
      this.$router.push({name: 'About'})
    }
  }
}
</script>

<style scoped lang="scss">

.title-text {
  font-size: 85px;
  margin-left: 100px;
}

.thin-logo {
  width: 280px;
}

.wrapper {
  background-image: url("../assets/img/auth/footer-light.svg");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.about-button {
  color: white;
  background-color: #57B992;
  padding: 1rem 8rem;
  border-radius: 12px;
  margin-top: 10rem;
}
</style>