<template>
  <v-form
    class="tectonic-form-container container d-flex flex-column"
    v-model="isValid"
    ref="form"
    lazy-validation
  >
    <v-text-field
      class="mb-2"
      label="Введите название шлифа"
      v-model="name"
      :rules="[v => v != null && v.length > 0 || 'Введите название']"
      autofocus
      @submit.prevent="addThinSection"
    ></v-text-field>

    <v-file-input
      accept="image/png, image/jpeg, image/bmp"
      prepend-icon="mdi-camera"
      label="Выберите изображение шлифа"
      v-model="image"
    ></v-file-input>

    <v-textarea
      solo
      label="Описание шлифа"
      hint="Описание шлифа"
      v-model="description"
    ></v-textarea>

    <v-btn
      @click="addThinSection"
      :disabled="isSending"
    >
      {{ buttonText }}
    </v-btn>
  </v-form>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "ThinSectionCreateUpdateForm",
  props: {
    sampleId: {
      required: true,
      type: [String, Number]
    },
    update: {
      default: false
    },
    // used for update action, otherwise it's undefined
    thinSectionId: {
      required: false,
      type: [String, Number]
    }
  },
  data() {
    return {
      isValid: true,

      description: '',
      name: '',
      image: null,

      isSending: false
    }
  },
  created() {
    this.description = this.thinSection?.description || ''
    this.name = this.thinSection?.name || ''
  },
  computed: {
    ...mapGetters(['getThinSection']),
    thinSection() {
      return this.getThinSection(this.thinSectionId)
    },
    buttonText() {
      return this.update ? 'Обновить' : 'Добавить'
    }
  },
  watch: {
    $props: {
      immediate: true,
      handler() {
        this.validateProps();
      }
    }
  },
  methods: {
    ...mapMutations(['ADD_THIN_SECTION', 'UPDATE_THIN_SECTION']),
    validateProps() {
      if (this.update && !this.thinSectionId) {
        throw new Error("The 'thin-section-id' props should be passed if 'update === true'")
      }
    },
    async addThinSection() {
      await this.$refs.form.validate()
      if (!this.isValid) {
        return
      }

      let formData = new FormData()
      formData.append('name', this.name)
      formData.append('sample', this.sampleId)
      console.log('Add image', this.image);
      if (this.image)
        formData.append('image.image', this.image)
      if (this.description)
        formData.append('description', this.description)

      this.isSending = true
      let response = null;
      if (this.update) {
        response = this.$api.patch(
          `thin-section/${this.thinSection.id}/`,
          formData
        )
      } else {
        response = this.$api.post(
          'thin-section/',
          formData
        )
      }

      response.then(async ({data}) => {
        this.isSending = false
        if (this.update) {
          this.UPDATE_THIN_SECTION(data)
        } else {
          this.ADD_THIN_SECTION(data)
          this.$router.push({name: 'Thin Section', params: {id: data.id}})
        }
        await this.$refs.form.reset()
        this.value = false
        this.$emit('close-window')
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.isSending = false
      })
    }
  }
}
</script>