import axios from "axios";
import auth from "@/http/auth";
import store from "@/store";
import router from "@/router";
import {BASE_URL, HTTP} from "./base";

const config = {
    baseURL: `${HTTP}://${BASE_URL}/rest`,
    withCredentials: true
}

const apiInstance = axios.create(config);

apiInstance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const {config, response} = err;

        if (response.status !== 401) {
            return Promise.reject(err);
        }

        if (response.config.url === 'refresh/') {
            await store.dispatch('logout')
            await router.push({name: 'Login'})
            return Promise.reject(err);
        }

        await auth.post("refresh/")
        return apiInstance(config);
    }
)

export default apiInstance;
