<template>
  <div>
    <TectonicCard :id="deposit.tectonic_confinement" />
    <div>
      <div class="domain">
        <div class="domain__title">
          Месторождение
        </div>
        <div class="domain__text" @click="navigate">
          {{ deposit.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TectonicCard from "@/components/domain/TectonicCard.vue";

export default {
  name: "DepositCard",
  components: {TectonicCard},
  props: {
    id: {
      type: Number,
      required: true
    },
    isRecursive: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['getDeposit']),
    deposit() {
      return this.getDeposit(this.id)
    }
  },
  methods: {
    navigate() {
      if (this.$route.name === 'Deposit') {
        return
      }
      this.$router.push(this.deposit.link)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>