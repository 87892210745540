<template>
  <div class="class-app-item">
    <label class="class-app-item__label" ><input class="class-app-item__checkbox" type="checkbox"  @click="changeClass">{{classes.name}} </label>
    <button class="class-app-item__button-color" :style="{backgroundColor: classes.colorHex}"></button>
  </div>
</template>

<script>

export default {
  name: "ClassAppItem",
  components: {},

  props:{
    classes: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  // computed:{
  //   ...mapGetters(['classes'])
  // },
  data(){
    return {

    }
  },
  methods:{
    changeClass(){
      this.$emit('changeClass')
    }
  },
  mounted() {
    // const cls = document.querySelectorAll('.class-app-item__button-color')
    // for (let i=0;i<cls.length;i++){
    //   cls[i].style.backgroundColor = this.classes.color[i]
    // }
  }
}
</script>

<style lang="scss" scoped>
  $margin: 5px;
  .class-app-item{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;

    &__label{
      font-size: 18px;

    }
    &__checkbox{
      margin: $margin;
      width: 5*$margin;
      height: 5*$margin;
      vertical-align: middle;
    }
    &__button-color {
      margin: $margin;
      border-radius: 10%;
      cursor: context-menu;
      width: 5*$margin;
      height: 5*$margin;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
</style>