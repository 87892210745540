<template>
  <v-container fluid>
    <v-row class="images-title mb-2">
      параллельные николи
    </v-row>
    <v-row v-if="parallelImage">
      <v-card
        width="300"
        class="image-card"
      >
        <img v-img="{src: parallelImage.image.origin}" style="width: 100%; height: 250px"
             :src="parallelImage.image.compressed" alt="">
        <v-card-title
          class="image-card__title justify-center"
        >
          Масштаб: {{ parallelImage.scale }} мм
        </v-card-title>
      </v-card>
    </v-row>
    <v-row v-else>
      Загрузите изображение в параллельных николях
    </v-row>
    <v-row class="images-title mt-10 mb-2">
      скрещенные николи
    </v-row>
    <v-row v-if="sortedCrossedImages.length">
      <v-col
        v-for="image in sortedCrossedImages"
        :key="image.id"
        cols="auto"
        class="pl-0"
      >
        <v-card
          width="300"
          class="image-card"
        >
          <img v-img="{src: image.image.origin}" style="width: 100%; height: 250px" :src="image.image.compressed"
               alt="">
          <v-card-title
            class="image-card__title justify-center"
          >
            Угол поворота: {{ image.grade }}
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      Загрузите изображение в скрещенных николях
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ImagesTab",
  props: ['crossedImages', 'parallelImage'],
  computed: {
    sortedCrossedImages() {
      return [...this.crossedImages].sort((a, b) => {
        return a.grade - b.grade
      })
    }
  }
}
</script>

<style scoped lang="scss">
.images-title {
  font-size: 25px;
  text-transform: uppercase;
}
</style>