<template>
  <div class="annotation">

    <div class="left-block">
      <!--    -------------------------------------------- Load data Component ------------------------------------------>
      <div class="load-data">
        <div class="load-data__item ">
          <div class="load-data__img ">
            <!--              <img :src=" require('../src/assets/imgs/Icon.png')">-->
          </div>
          <div class="load-data__title ">
            <label for="image">Добавить изображение или анимацию</label>
          </div>
          <input style="display: none" id = 'image' type="file" accept="image/*" @change="uploadImagesFromLocal" multiple/>
        </div>

        <div class="load-data__item ">
          <div class="load-data__img ">
            <!--        <img :src=" require('../assets/Exclude.png')">-->
          </div>
          <div class="load-data__title ">
            <label for="mask">Загрузить готовую маску</label>
          </div>
          <input style="display: none" id = 'mask' type="file" accept="image/*" @change="uploadMaskFromLocal"/>
        </div>

        <img class='hide-img' :src=" require('../../assets/imgs/Carbonate sandstones_32050_7_2.png')" alt=""/>
        <img class='hide-img' :src=" require('../../assets/imgs/Carbonate sandstones_32050_7_2.gif')" alt=""/>
        <img class='hide-img' :src=" require('../../assets/imgs/Carbonate sandstones_32050_7_2_ll.png')" alt=""/>
        <img class='hide-img' :src=" require('../../assets/imgs/img_web_labels.png')" alt=""/>

        <div class="checkboxesLoad">
          <label class="cbxLoad">
            <input type="checkbox"  class="cbxImageBack" id="overlay" @click="changeImageBack" disabled>Скрещенные николи
          </label>
          <label class="cbxLoad">
            <input type="checkbox" class="cbxImageBack" id="animation" @click="changeImageBack" disabled>Анимация
          </label>
          <label for="ll" class="cbxLoad">
            <input type="checkbox"  class="cbxImageBack" id="ll" @click="changeImageBack" disabled>Параллельные николи
          </label>
        </div>
      </div>

      <!--    -------------------------------------------- Class App Component ------------------------------------------>
      <div class="class-app">
        <ClassAppItem
            v-for="(cls,idx) in classes"
            :key="cls.id"
            v-bind:classes="cls"
            @changeClass="changeClass(idx)"
        />
      </div>

      <!--    -------------------------------------------- Base Buttons Component ------------------------------------------>
      <div class="base-buttons">
        <div class="base-buttons__title">Основные кнопки</div>
        <button class='base-buttons__button' id="apply" name="hotkey" title="Hot key: A" @click="apply_selection">применить</button>
        <button class='base-buttons__button' id="dualCancel" name="hotkey" title="Hot key: Shift+C"  @click="dualCancel" style="display: none">dual cancel</button>
        <button class='base-buttons__button' id="smartSelection" name="hotkey" title="Hot key: S" @click="smartSelection">умная разметка</button>
        <button class='base-buttons__button' id="cancel" name="hotkey" title="Hot key: C"  @click="cancel">отмена</button>
        <button class='base-buttons__button' id="eraseMask" name="hotkey" title="Hot key: V" @click="eraseMask">стереть</button>
        <button class='base-buttons__button' id="hide" name="hotkey" title="Hot key: Space" @click="hide">скрыть</button>
        <button class='base-buttons__button' id="zoom" name="hotkey" title="Hot key: Q" @click="zoomBtn">+</button>
        <button class='base-buttons__button' id="clear" @click="clear">удалить</button>
        <button class='base-buttons__button' id="save" @click="save">сохранить</button>
        <button class='base-buttons__button' id="zoomOut" name="hotkey" title="Hot key: W" @click="downBtn">-</button>
        <div class="base-buttons__parameters">
          <div class="parameters_title" title="Parameter for 'smart selection'"><p>Толщина границы</p></div>
          <div class="parameters_value"><input class="parameters_value input" id="borderThickness" type="number" max="100" min="1" v-model="borderThickness"></div>
        </div>
      </div>

      <!--    -------------------------------------------- Tools Buttons Component ------------------------------------------>
      <div class="tools-buttons">
        <div class="tools-buttons__left">
          <div class="base-buttons__title">Блок инструментов</div>
          <button class='base-buttons__button tool' id="brush" name="hotkey" title="Hot key: B" :style="{backgroundColor: toolsBtn[0].color}" @click="toolSelected">кисть</button>
          <button class='base-buttons__button tool' id="dilation" name="hotkey" title="Hot key: X" @click="dilation">расширение</button>
          <button class='base-buttons__button tool' id="erosion" name="hotkey" title="Hot key: Z" @click="erosion">сжатие</button>
          <button class='base-buttons__button tool' id="magicWand" name="hotkey" title="Hot key: M" :style="{backgroundColor: toolsBtn[1].color}" @click="toolSelected">волшебная палочка</button>
          <button class='base-buttons__button tool' id="highlightColor" name="hotkey" title="Hot key: Shift+A" @click="highlightColorBtn">выделить класс</button>
          <button class='base-buttons__button tool' id="filling" name="hotkey" title="Hot key: G" :style="{backgroundColor: toolsBtn[3].color}" @click="toolSelected">выделить объект</button>
          <button class='base-buttons__button tool' id="eraseSelection" name="hotkey" title="Hot key: E" :style="{backgroundColor: toolsBtn[2].color}" @click="toolSelected">ластик</button>
          <button class='base-buttons__button tool' id="areaFill" name="hotkey" title="Hot key: F" @click="areaFill">заливка</button>
        </div>
        <div class="tools-buttons__right">
          <div class="parameters_title" title="Parameter for 'brush'. Hot key: Ctrl + Scroll"><p>Размер кисти</p></div>
          <div class="parameters_value"><input class="parameters_value input" id="lineWidth" type="number" max="200" min="1" v-model="lineWidth"></div>

          <div class="parameters_title" title="Parameter for 'dilation' and 'erosion'"><p>Размер ядра</p></div>
          <div class="parameters_value"><input class="parameters_value input" id="kernelSize" type="number" step="2" max="101" min="3" v-model="kernelSize"></div>

          <div class="parameters_title" title="Parameter for 'magic wand'"><p>Радиус области</p></div>
          <div class="parameters_value"><input class="parameters_value input" id="radiusMagicWand" step="5" type="number" max="100" min="1" v-model="radiusMagicWand"></div>

          <div class="parameters_title" title="Parameter for 'magic wand'"><p>Чувствительность</p></div>
          <div class="parameters_value"><input class="parameters_value input" id="toleranceMagicWand" type="number" step="1" max="255" min="1" v-model="toleranceMagicWand"></div>
        </div>
      </div>
      <!--      <v-img-->
      <!--          :src="require('@/assets/imgs/Icon.svg')"-->
      <!--          height="70vh"-->
      <!--          fill="red"-->
      <!--          contain-->
      <!--      ></v-img>-->
      <v-row>
        <v-col>
          <button class="save-button" @click="closeAnnotationToolPage"  style="background-color: #B95757; color: white">
            Вернуться назад
          </button>
        </v-col>
        <v-col>
          <button class="save-button" @click="saveResults">
            Сохранить результаты
          </button>
        </v-col>
      </v-row>
    </div>

    <!--    -------------------------------------------- Canvas App Component ------------------------------------------>
    <div class="canvas-app">
      <canvas id="canvas" width="400" height="400"
              @mousedown="mouseDown"
              @mouseup="mouseUp"
              @mousemove="paint"
              @mouseleave="mouseLeave"
              @wheel.prevent="mouseWheel"
      >
      </canvas>
      <div id="cursor-rounded"></div>
      <canvas id="canvas-hide" width="400" height="400"></canvas>
      <canvas id="canvas-test" width="1450" height="1450" style="display: none"></canvas>
    </div>
  </div>
</template>

<script>
import * as cv from "opencv.js";
import ClassAppItem from "@/components/annotation/ClassAppItem";
// import analyzeService from "@/http/service/analyze-service";
import {mapGetters} from "vuex";

export default {
  name: "AnnotationTool",
  components:{
    ClassAppItem
  },
  data(){
    return {
      classes: [
        {
          id: 1,
          name: "Границы",
          color: [255,255,0],
          colorHex: this.RGBToHex(255,255,0)
        },
        {
          id: 2,
          name: "Кварц",
          color: [255,193,203],
          colorHex: this.RGBToHex(255,193,203)
        },
        {
          id: 3,
          name: "Слюда",
          color: [255,165,0],
          colorHex: this.RGBToHex(255,165,0)
        },
        {
          id: 4,
          name: "Рудные минералы",
          color: [0,129,0],
          colorHex: this.RGBToHex(0,129,0)
        },
        {
          id: 5,
          name: "Глина",
          color: [255,0,0],
          colorHex: this.RGBToHex(255,0,0)
        },
        {
          id: 6,
          name: "Карбонаты",
          color: [0,0,255],
          colorHex: this.RGBToHex(0,0,255)
        },
        {
          id: 7,
          name: "Глинистый цемент",
          color: [255,255,255],
          colorHex: this.RGBToHex(255,255,255)
        },
        {
          id: 8,
          name: "Неопределенные области",
          color: [128,0,128],
          colorHex: this.RGBToHex(128,0,128)
        },
        {
          id: 9,
          name: "Спарит",
          color: [0,255,255],
          colorHex: this.RGBToHex(0,255,255)
        },
        {
          id: 10,
          name: "Микрит",
          color: [135,206,235],
          colorHex: this.RGBToHex(135,206,235)
        },
        {
          id: 11,
          name: "Остатки организмов",
          color: [165,42,42],
          colorHex: this.RGBToHex(165,42,42)
        },
      ],
      loadImages: [
        {
          id: 0,
          name:'Animation'
        },
        {
          id: 1,
          name: 'Overlay image'
        },
        {
          id:2,
          name:'ll image'
        }
      ],
      isDebug: false,
      isFromPredictModule: false,
      imagesBack: [],
      history: [],
      colorLabel: [0,255,0],
      currentColor: [0,255,0],
      scaleCoefficient: 2,
      scale: 2,
      scaleBegin: 2,
      lineWidth: 30,
      sizeHistory: 15,
      alpha: 255,
      kernelSize: 5,
      borderThickness: 3,
      widthSquareForTransparency: 40,
      isHide: true,
      isImageLoad: false,
      colorBtn: '#093545',
      colorBtnSelected: '#ff00ff',
      toolsBtn:[
        {tool:'brush', isSelected:true, color:'#ff00ff', cursorRounded:{cursor:'none', outline: '2px solid #FF0'}},
        {tool:'magicWand', isSelected:false, color:'', cursorRounded:{cursor:'crosshair', outline: '0px solid #FF0'}},
        {tool:'eraseSelection', isSelected:false, color:'', cursorRounded:{cursor:'none',outline: '2px solid #FF0000'}},
        {tool:'filling', isSelected:false, color:'', cursorRounded:{cursor:'crosshair', outline: '0px solid #FF0'}},
      ],
      toleranceMagicWand: 32,
      radiusMagicWand: 100,
    }
  },

  methods: {
    closeAnnotationToolPage(){
      this.$router.push({
        name: 'Thin Section Plot'
      })
    },
    dataURLtoFile(dataUrl, filename) {
      let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type:mime});
    },

    saveResults(){
      this.$router.push({
        name: 'Thin Section Plot'
      })
      // this.ADD_ANALYZE({
      //   thinSectionPlotId: this.thinSectionPlot.id,
      //   analyze: data}) {
      //   state.thinSectionPlots[payload.thinSectionPlotId].analyze = payload.analyze
      // },
      // this.getThinSectionPlot.analyze.status = 'in progress'
      let imageData = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let pixels = imageData.data
      let classes_colors = this.classes.map(cls => cls.color)
      classes_colors.unshift([0, 0, 0])
      console.log(this.getColorPixel(pixels, 0));
      for(let i=0;i<pixels.length;i=i+4){
        let newColor = classes_colors.map(color =>this.compare(color,this.getColorPixel(pixels,i))).indexOf(true)
        this.setColorPixel(pixels,i,[newColor,newColor,newColor])
        pixels[i+3] = 255;
      }
      this.ctxHide.putImageData(imageData, 0, 0);

      let maskUrl = this.canvasHide.toDataURL("imag/png")
      // console.log('Mask Url: ', maskUrl);
      let new_mask = this.dataURLtoFile(maskUrl, 'mask.png');
      console.log('Mask: ', new_mask);

      let formData = new FormData()

      formData.append('thin_section_plot', this.$route.params.id)
      formData.append('origin', new_mask)
      // formData.append('type', 'granulometric analyze')
      let request = this.$api.post('/annotation/', formData)
      console.log(request);
      request.then(({data}) => {
        this.ADD_ANALYZE({
          thinSectionPlotId: this.thinSectionPlot.id,
          analyze: data
        })
      })
          .catch(err => {
            console.log(err)
          })
      // analyzeService.annotationSave(this.getThinSectionPlot(this.$route.params.id), new_mask)
    },
    uploadMask(mask) {
      console.log('Load masks');
      let annotationImage = new Image()
      if (this.isDebug) {
        annotationImage.src = 'http://localhost:8080/img/img_web_labels.0052de97.png';
      } else if (mask[0].name.indexOf('http') !== -1) {
        annotationImage.crossOrigin = "Anonymous";
        annotationImage.src = mask[0].name
        this.isFromPredictModule = true
      } else {
        annotationImage.src = URL.createObjectURL(mask[0]);
      }
      let thisObject = this
      annotationImage.onload = function () {
        console.log(annotationImage.width,thisObject.canvasHide);
        thisObject.canvasHide.width = annotationImage.width
        thisObject.canvasHide.height = annotationImage.height
        thisObject.ctxHide.strokeStyle = thisObject.RGBToHex(thisObject.colorLabel[0],
            thisObject.colorLabel[1], thisObject.colorLabel[2])
        thisObject.ctxHide.lineCap = "round"
        thisObject.ctxHide.imageSmoothingEnabled = false
        thisObject.ctxHide.drawImage(annotationImage, 0, 0);
        if (thisObject.isFromPredictModule) {
          let imageData = thisObject.ctxHide.getImageData(0, 0, thisObject.canvasHide.width, thisObject.canvasHide.height);
          let pixels = imageData.data
          for (let i = 0; i < pixels.length; i += 4) {
            if (pixels[i] === 0) {
              continue
            }
            thisObject.setColorPixel(pixels, i, thisObject.classes[pixels[i] - 1].color)
          }
          thisObject.ctxHide.putImageData(imageData, 0, 0);
        }
        thisObject.setTransparency()
        thisObject.ctxHideToCtx()
        thisObject.currentImageData = thisObject.ctxHide.getImageData(0, 0, thisObject.canvasHide.width, thisObject.canvasHide.height);
        thisObject.history.unshift(thisObject.currentImageData)
        thisObject.annotationImage = annotationImage
      }
    },

    uploadMaskFromLocal(event){
      this.uploadMask(event.target.files)
    },

    uploadImagesFromLocal(event){
      console.log(event.target.files);
      this.uploadImages(event.target.files)
    },

    uploadImages(images) {
      this.canvasTest = document.querySelector("#canvas-test");
      this.ctxTest = this.canvasTest.getContext("2d");
      this.canvasTest.width = 1450
      this.canvasTest.height = 1450
      this.ctxTest.strokeStyle = this.RGBToHex(this.colorLabel[0], this.colorLabel[1], this.colorLabel[2])
      this.ctxTest.lineCap = "round"
      this.ctxTest.imageSmoothingEnabled = false

      console.log('Load images');
      const canvas = document.querySelector("#canvas")
      const ctx = canvas.getContext("2d")
      canvas.style.imageRendering = 'pixelated'
      canvas.height = 700
      canvas.width = 700
      ctx.strokeStyle = this.RGBToHex(this.colorLabel[0],
          this.colorLabel[1], this.colorLabel[2])
      ctx.lineCap = "round"
      ctx.lineWidth = this.lineWidth
      ctx.globalAlpha = 0.5
      ctx.imageSmoothingEnabled = false

      this.cbxImageBack = document.querySelectorAll('.cbxImageBack')
      this.canvasHide = document.querySelector("#canvas-hide");
      this.ctxHide = this.canvasHide.getContext("2d");
      this.canvas = document.querySelector("#canvas");
      this.ctx = ctx
      this.canvas = canvas

      let imagesBack = []
      let thisObject = this
      if (this.isDebug) {
        this.imageLoad(0, 'http://localhost:8080/img/Carbonate%20sandstones_32050_7_2.e18d3ddb.png', imagesBack)
        imagesBack[0].image.onload = function () {
          thisObject.updateCanvasParameter(imagesBack[0].url, imagesBack[0].image)
        }
        this.imageLoad(1, 'http://localhost:8080/img/Carbonate sandstones_32050_7_2.7680136f.gif', imagesBack)
        imagesBack[1].image.onload = function () {
          thisObject.updateCanvasParameter(imagesBack[1].url, imagesBack[1].image)
        }
        this.imageLoad(2, 'http://localhost:8080/img/Carbonate sandstones_32050_7_2_ll.35ae8432.png', imagesBack)
        imagesBack[2].image.onload = function () {
          thisObject.updateCanvasParameter(imagesBack[2].url, imagesBack[2].image)
        }
        this.updateCheckboxesLoad(imagesBack)
      } else {
        this.canvasHide.style.display = 'none'
        if (images.length > 0 && images.length <= 3) {
          for (let i = 0; i < images.length; i++) {
            if (images[i].type === 'image/gif') {
              this.imageLoad(1, images[i], imagesBack)
              imagesBack[1].image.onload = function () {
                thisObject.updateCanvasParameter(imagesBack[0].url, imagesBack[0].image)
              }
            } else if ((images[i].name).indexOf('ll') !== -1 || (images[i].type).indexOf('ll') !== -1) {
              this.imageLoad(2, images[i], imagesBack)
              imagesBack[2].image.onload = function () {
                thisObject.updateCanvasParameter(imagesBack[2].url, imagesBack[2].image)
              }
            } else {
              this.imageLoad(0, images[i], imagesBack)
              imagesBack[0].image.onload = function () {
                thisObject.updateCanvasParameter(imagesBack[1].url, imagesBack[1].image)
              }
            }
          }
        }
        this.updateCheckboxesLoad(imagesBack)
      }
      this.isImageLoad = imagesBack.length > 0;

      if (!this.isFromPredictModule) {
        this.currentImageData = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
        this.history.unshift(this.currentImageData)
      }
      this.imagesBack = imagesBack
    },

    changeImageBack(event) {
      for (let j = 0; j < this.cbxImageBack.length; j++) {
        if (this.cbxImageBack[j] !== event.target) {
          this.cbxImageBack[j].checked = false
        } else {
          this.cbxImageBack[j].checked = true
          this.thisImageBack = this.imagesBack[j].image
          this.switchBackgroundImage(this.imagesBack[j].url)
        }
      }
    },


    hotKeys(event) {
      console.log(event);
    },
    mouseDown() {
      this.isDrawing = true
    },
    mouseUp(event) {
      this.isDrawing = false
      this.apply(this.colorLabel)
      if(!this.isHide) {
        this.isHide = true
      }
      this.ctxHideToCtx()
      if (this.toolsBtn[3].isSelected) {
        this.filling(event)
      } else if (this.toolsBtn[1].isSelected) {
        this.magicWand(event)
      }
    },
    mouseLeave() {
      this.isDrawing = false
      this.cursorRounded.style.display = 'none'
    },
    mouseWheel(event) {
      if (event.ctrlKey) {
        this.changeCursorRadius(event)
      } else {
        if (event.deltaY < 0) {
          this.zoomBtn()
        } else {
          this.downBtn()
        }
      }
    },

    zoomBtn() {
      this.setTransparency()
      let currentPointX = this.prevX
      let currentPointY = this.prevY
      let w = (this.canvas.width - (this.canvas.width / this.scaleBegin)) / 2
      let h = (this.canvas.height - (this.canvas.height / this.scaleBegin)) / 2
      if (currentPointX < w) {
        currentPointX = w
      } else if (currentPointX > 3 * w) {
        currentPointX = 3 * w
      }
      if (currentPointY < h) {
        currentPointY = h
      } else if (currentPointY > 3 * h) {
        currentPointY = 3 * h
      }
      if (this.scale === this.scaleBegin) {
        this.prevHideX = currentPointX
        this.prevHideY = currentPointY
      } else {
        currentPointX = this.prevHideX - (this.canvas.width / 2 - currentPointX) / (this.scale / 2)
        currentPointY = this.prevHideY - (this.canvas.height / 2 - currentPointY) / (this.scale / 2)
      }
      this.scaleCtx(currentPointX, currentPointY)
    },
    downBtn() {
      this.setTransparency()
      this.scale /= 4
      if (this.scale < 1) {
        this.scale = this.scaleBegin
        return
      } else if (this.scale === 1) {
        if (this.isHide) {
          this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
          this.ctx.drawImage(this.canvasHide,
              0, 0,
              this.canvas.width, this.canvas.height)
        }
        this.redrawBackroundImage()
        this.scale = this.scaleBegin
        return
      }
      let currentPointX = this.prevX
      let currentPointY = this.prevY
      currentPointX = this.prevHideX - (this.canvas.width / 2 - currentPointX) / (2 * this.scale)
      currentPointY = this.prevHideY - (this.canvas.height / 2 - currentPointY) / (2 * this.scale)
      let w = this.thisImageBack.width / (2 * this.scale)
      let h = this.thisImageBack.height / (2 * this.scale)
      if (currentPointX * this.scaleCoefficient - w < 0) {
        currentPointX = w / this.scaleCoefficient
      } else if (currentPointX * this.scaleCoefficient + w > this.canvasHide.width) {
        currentPointX = (this.canvasHide.width - w) / this.scaleCoefficient
      }
      if (currentPointY * this.scaleCoefficient - h < 0) {
        currentPointY = h / this.scaleCoefficient
      } else if (currentPointY * this.scaleCoefficient + h > this.canvasHide.height) {
        currentPointY = (this.canvasHide.height - h) / this.scaleCoefficient
      }
      this.scaleCtx(currentPointX, currentPointY)
    },

    scaleCtx(currentPointX, currentPointY) {
      if (this.isHide) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        this.ctx.drawImage(this.canvasHide,
            (currentPointX * this.scaleCoefficient - this.thisImageBack.width / (2 * this.scale)),
            (currentPointY * this.scaleCoefficient - this.thisImageBack.height / (2 * this.scale)),
            this.thisImageBack.width / this.scale, this.thisImageBack.height / this.scale,
            0, 0,
            this.canvas.width, this.canvas.height)
      }
      this.redrawBackroundImage(currentPointX, currentPointY)
      this.scale *= 2
      this.prevHideX = currentPointX
      this.prevHideY = currentPointY
    },

    redrawBackroundImage(currentPointX = 0, currentPointY = 0) {
      for (let i = 0; i < this.cbxImageBack.length; i++) {
        if (this.cbxImageBack[i].checked) {
          this.canvas.style.background = this.imagesBack[i].url
          break
        }
      }
      this.canvas.style.backgroundSize = `${this.scale * 100}% ${this.scale * 100}%`
      if (this.scale === 1) {
        this.canvas.style.backgroundPositionX = `-${this.canvas.width}px`
        this.canvas.style.backgroundPositionY = `-${this.canvas.height}px`
      } else {
        this.canvas.style.backgroundPositionX = `-${this.canvas.width * (this.scale / 2) - this.canvas.width / 2 - this.scale * (this.canvas.width / 2 - currentPointX)}px`
        this.canvas.style.backgroundPositionY = `-${this.canvas.height * (this.scale / 2) - this.canvas.height / 2 - this.scale * (this.canvas.height / 2 - currentPointY)}px`
      }
    },

    paint(event) {
      if (this.imagesBack.length === 0) {
        return;
      }
      this.updateCursor(event)
      if (this.prevX == null || this.prevY == null || !this.isDrawing || !this.isImageLoad) {
        this.prevX = event.offsetX
        this.prevY = event.offsetY
        return
      }
      const currentX = event.offsetX
      const currentY = event.offsetY

      if (event.shiftKey) {
        this.cursorRounded.style.outline = '0px solid #FF0'
        this.canvas.style.cursor = 'grab'
        this.moveImg(this.prevX, this.prevY, currentX, currentY);
        return;
      }
      this.ctx.beginPath()
      this.ctx.moveTo(this.prevX, this.prevY)
      this.ctx.lineTo(currentX, currentY)
      this.ctx.stroke()
      this.ctx.globalAlpha = 0.5

      this.paintHideImage(currentX, currentY)
      this.paintTestImage(currentX, currentY)
      this.prevX = currentX
      this.prevY = currentY

      if(this.isHide) {
        this.ctxHideToCtx()
      }
      // this.setTransparencyInSquare(currentX, currentY)
    },

    changeCursorRadius(event) {
      let changeValue
      if (this.lineWidth < 10) {
        changeValue = 1
      } else {
        changeValue = 5
      }
      let newValue
      if (event.deltaY < 0) {
        newValue = this.lineWidth + changeValue
      } else {
        newValue = this.lineWidth - changeValue
      }

      const lineWidthEl = document.querySelector('#lineWidth')
      if (newValue > parseInt(lineWidthEl.getAttribute('max'))) {
        newValue = parseInt(lineWidthEl.getAttribute('max'))
      } else if (newValue <= 0) {
        newValue = 1
      }
      this.lineWidth = newValue
      lineWidthEl.setAttribute('value', `${newValue}`)
      this.ctx.lineWidth = this.lineWidth
      this.updateCursor(event)
    },

    updateCursor(event) {
      let radius = this.lineWidth
      this.cursorRounded.style.width = `${radius}px`
      this.cursorRounded.style.height = `${radius}px`
      let currentToolIndex = this.toolsBtn.map(value => value.isSelected).indexOf(true)
      this.cursorRounded.style.display = 'flex'
      this.cursorRounded.style.outline = this.toolsBtn[currentToolIndex].cursorRounded.outline
      this.canvas.style.cursor = this.toolsBtn[currentToolIndex].cursorRounded.cursor
      const currentX = event.offsetX - radius / 2 + 3
      const currentY = event.offsetY - radius / 2 - 6 - this.canvas.height
      this.cursorRounded.style.transform = `translate3d(${currentX}px, ${currentY}px, 0)`;
    },

    paintTestImage(currentX, currentY) {

      if (this.prevHideX == null || this.prevHideY == null || !this.isDrawing) {
        this.prevHideX = this.prevX
        this.prevHideY = this.prevY
        console.log(this.prevHideX, this.prevHideY);
        return
      }

      this.ctxTest.lineWidth = this.lineWidth * this.scaleCoefficient / (this.scale / 2)


      if (this.scale === 2) {
        this.prevHideX = this.prevX
        this.prevHideY = this.prevY

        if (this.toolsBtn[2].isSelected) {
          this.eraseSelection([currentX * this.scaleCoefficient, currentY * this.scaleCoefficient])
          return
        }

        this.ctxTest.beginPath()
        this.ctxTest.moveTo(this.prevHideX * this.scaleCoefficient, this.prevHideY * this.scaleCoefficient)
        this.ctxTest.lineTo(currentX * this.scaleCoefficient, currentY * this.scaleCoefficient)
        this.ctxTest.stroke()
        this.prevHideX = currentX
        this.prevHideY = currentY
      } else {
        let prevX_2 = this.prevHideX - (this.canvas.width / 2 - this.prevX) / (this.scale / 2)
        let prevY_2 = this.prevHideY - (this.canvas.width / 2 - this.prevY) / (this.scale / 2)
        let currentX_2 = this.prevHideX - (this.canvas.width / 2 - currentX) / (this.scale / 2)
        let currentY_2 = this.prevHideY - (this.canvas.width / 2 - currentY) / (this.scale / 2)

        prevX_2 = (prevX_2 * this.scaleCoefficient)
        prevY_2 = (prevY_2 * this.scaleCoefficient)
        currentX_2 = (currentX_2 * this.scaleCoefficient)
        currentY_2 = (currentY_2 * this.scaleCoefficient)

        this.ctxTest.lineWidth = this.lineWidth * this.scaleCoefficient / (this.scale / 2)

        if (this.toolsBtn[2].isSelected) {
          this.eraseSelection([currentX_2, currentY_2])
          return
        }
        this.ctxTest.beginPath()
        this.ctxTest.moveTo(prevX_2, prevY_2)
        this.ctxTest.lineTo(currentX_2, currentY_2)
        this.ctxTest.stroke()
      }
    },

    paintHideImage(currentX, currentY) {

      if (this.prevHideX == null || this.prevHideY == null || !this.isDrawing) {
        this.prevHideX = this.prevX
        this.prevHideY = this.prevY
        console.log(this.prevHideX, this.prevHideY);
        return
      }

      this.ctxHide.lineWidth = this.lineWidth * this.scaleCoefficient / (this.scale / 2)


      if (this.scale === 2) {
        this.prevHideX = this.prevX
        this.prevHideY = this.prevY

        if (this.toolsBtn[2].isSelected) {
          this.eraseSelection([currentX * this.scaleCoefficient, currentY * this.scaleCoefficient])
          return
        }

        this.ctxHide.beginPath()
        this.ctxHide.moveTo(this.prevHideX * this.scaleCoefficient, this.prevHideY * this.scaleCoefficient)
        this.ctxHide.lineTo(currentX * this.scaleCoefficient, currentY * this.scaleCoefficient)
        this.ctxHide.stroke()
        this.prevHideX = currentX
        this.prevHideY = currentY
      } else {
        let prevX_2 = this.prevHideX - (this.canvas.width / 2 - this.prevX) / (this.scale / 2)
        let prevY_2 = this.prevHideY - (this.canvas.width / 2 - this.prevY) / (this.scale / 2)
        let currentX_2 = this.prevHideX - (this.canvas.width / 2 - currentX) / (this.scale / 2)
        let currentY_2 = this.prevHideY - (this.canvas.width / 2 - currentY) / (this.scale / 2)

        prevX_2 = (prevX_2 * this.scaleCoefficient)
        prevY_2 = (prevY_2 * this.scaleCoefficient)
        currentX_2 = (currentX_2 * this.scaleCoefficient)
        currentY_2 = (currentY_2 * this.scaleCoefficient)

        this.ctxHide.lineWidth = this.lineWidth * this.scaleCoefficient / (this.scale / 2)

        if (this.toolsBtn[2].isSelected) {
          this.eraseSelection([currentX_2, currentY_2])
          return
        }
        this.ctxHide.beginPath()
        this.ctxHide.moveTo(prevX_2, prevY_2)
        this.ctxHide.lineTo(currentX_2, currentY_2)
        this.ctxHide.stroke()
      }
    },


    setTransparencyInSquare(currentX, currentY) {
      let isCrop = false
      let imageData
      let temp = this.widthSquareForTransparency+this.lineWidth
      if(temp<50) {
        temp = 50
      }
      if (currentX - temp >= 0 && currentY - temp >= 0 && currentX + temp <= this.thisImageBack.width && currentY + temp <= this.thisImageBack.height) {
        imageData = this.ctx.getImageData(currentX - temp, currentY - temp, 2 * temp, 2 * temp);
        isCrop = true
      } else {
        imageData = this.ctx.getImageData(0, 0, this.canvas.width, this.canvas.height);
        isCrop = false
      }
      let pixels = imageData.data
      let alpha = this.alpha
      for (let i = 0; i < pixels.length; i += 4) {
        if (pixels[i] !== 0 || pixels[i + 1] !== 0 || pixels[i + 2] !== 0) {
          pixels[i + 3] = alpha
        }
      }
      if (isCrop) {
        this.ctx.putImageData(imageData, currentX - temp, currentY - temp);
      } else {
        this.ctx.putImageData(imageData, 0, 0);
      }
      this.prevX = currentX
      this.prevY = currentY
    },

    magicWand(e) {
      let beginPoint = this.getCurrentPoint(e)
      if (beginPoint === undefined) {
        return
      }
      let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let pixelsInputImg = image.data
      this.ctxHide.drawImage(this.thisImageBack, 0, 0)
      let src = cv.imread('canvas-hide');
      cv.cvtColor(src, src, cv.COLOR_RGB2HSV, 0);
      cv.cvtColor(src, src, cv.COLOR_RGB2RGBA, 0);
      let pixelsHSVImg = src.data
      let tolerance = this.toleranceMagicWand
      let selectedColorHSV = this.get(pixelsHSVImg, beginPoint[0], beginPoint[1])
      let low = new cv.Mat(src.rows, src.cols, src.type(), [selectedColorHSV[0] - tolerance,
        selectedColorHSV[1] - tolerance, 0, 0].map(this.crop));
      let high = new cv.Mat(src.rows, src.cols, src.type(), [selectedColorHSV[0] + tolerance,
        selectedColorHSV[1] + tolerance, 255, 255].map(this.crop));
      // console.log(beginPoint,selectedColorHSV,[selectedColorHSV[0]-tolerance,
      //   selectedColorHSV[1]-tolerance, 0,0].map(this.crop),[selectedColorHSV[0]+tolerance,
      //   selectedColorHSV[1]+tolerance, 255,255].map(this.crop))
      let dst = new cv.Mat();
      cv.inRange(src, low, high, dst);

      let contours = new cv.MatVector();
      let hierarchy = new cv.Mat();
      cv.findContours(dst, contours, hierarchy, cv.RETR_TREE, cv.CHAIN_APPROX_NONE);
      let out = cv.Mat.zeros(src.cols, src.rows, cv.CV_8UC3);
      for (let i = 0; i < contours.size(); i++) {
        let ind = contours.get(i).data32S.indexOf(beginPoint[1])
        let ind2 = contours.get(i).data32S.indexOf(beginPoint[0])
        if (ind % 2 === 0 && ind2 > 0) {
          let color = new cv.Scalar(255, 255, 255);
          cv.drawContours(out, contours, i, color, -1, cv.LINE_8, hierarchy, 0);
          // console.log(this.get(out.data,beginPoint[0],beginPoint[1],3),this.compare(this.get(out.data,beginPoint[0],beginPoint[1],3),[255,255,255]))
          if (this.compare(this.get(out.data, beginPoint[0], beginPoint[1], 3), [255, 255, 255])) {
            break
          }
          out = cv.Mat.zeros(src.cols, src.rows, cv.CV_8UC3);
        }
      }
      cv.cvtColor(out, out, cv.COLOR_RGB2RGBA, 0);
      let pixels = out.data
      let colorLabel = this.colorLabel
      for (let i = 0; i < pixels.length; i += 4) {
        let x = (i / 4) % (this.canvasHide.width)
        let y = Math.floor((i / 4) / (this.canvasHide.height))
        if (this.compare(this.getColorPixel(pixels, i), [255, 255, 255]) && this.getDistance(beginPoint, [y, x]) < this.radiusMagicWand) {
          this.setColorPixel(pixelsInputImg, i, colorLabel, 1)
        }
      }
      this.ctxHide.putImageData(image, 0, 0)
      this.ctxHideToCtx()
      src.delete();
      out.delete();
      contours.delete();
      hierarchy.delete();
    },

    filling(e) {
      let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let pixels = image.data
      let beginPoint = this.getCurrentPoint(e)
      let arr = [beginPoint]
      let selectedColor = this.get(pixels, beginPoint[0], beginPoint[1])
      console.log('Selected Color: ', selectedColor);
      let iter = 0
      while (arr.length > 0 && iter < 1e5) {
        let data = this.checkInput(pixels, selectedColor, beginPoint, arr, [[arr[0][0], arr[0][1] + 1], [arr[0][0], arr[0][1] - 1], [arr[0][0] - 1, arr[0][1]], [arr[0][0] + 1, arr[0][1]]])
        for (let k of data) {
          arr.push(k)
        }
        this.setColorPixel(pixels, arr[0][0] * this.canvasHide.width * 4 + 4 * arr[0][1], this.colorLabel, 1)
        arr.shift()
        iter = iter + 1
      }
      this.ctxHide.putImageData(image, 0, 0)
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      this.ctxHideToCtx()
      console.log('Iterations: ', iter, ' Finally filling')
    },

    checkInput(pixels, selectedColor, beginPoint, arr, data) {
      let dataNew = []
      for (let k = 0; k < data.length; k++) {
        let isOk = true
        for (let i = 0; i < arr.length; i++) {
          if (data[k][0] === arr[i][0] && data[k][1] === arr[i][1]) {
            isOk = false
          }
        }
        if (!this.compare(selectedColor, this.get(pixels, data[k][0], data[k][1]))) {
          isOk = false
        }
        // if(ismagicWandBtn && getDistance(beginPoint,[data[k][0],data[k][1]])>parseInt(radiusMagicWand.value)){
        //   isOk=false
        // }
        if (isOk) {
          dataNew.push(data[k])
        }
      }
      return dataNew
    },
    getCurrentPoint(e) {
      let x = e.offsetY
      let y = e.offsetX
      if (x < 0 || x > this.canvas.height || y < 0 || y > this.canvas.width) {
        return
      }
      if (this.scale === 2) {
        x = parseInt(x * this.scaleCoefficient)
        y = parseInt(y * this.scaleCoefficient)
      } else {
        y = this.prevHideX - (this.canvas.width / 2 - y) / (this.scale / 2)
        x = this.prevHideY - (this.canvas.width / 2 - x) / (this.scale / 2)
        y = parseInt(y * this.scaleCoefficient)
        x = parseInt(x * this.scaleCoefficient)
      }
      return [x, y]
    },
    get(pixels, mouseY, mouseX, dim = 4) {
      return [pixels[mouseY * this.canvasHide.width * dim + dim * mouseX], pixels[mouseY * this.canvasHide.width * dim + dim * mouseX + 1], pixels[mouseY * this.canvasHide.width * dim + dim * mouseX + 2]]
    },


    moveImg(prevX, prevY, currentX, currentY) {
      let difX = currentX - prevX
      let difY = currentY - prevY
      if (Math.abs(difX) < 10 && Math.abs(difY) < 10) {
        return
      }
      let x = this.prevHideX - difX / this.scale
      let y = this.prevHideY - difY / this.scale
      if (x < this.canvas.width / (this.scale) || y < this.canvas.height / (this.scale) ||
          y > this.canvas.height - this.canvas.height / (this.scale) || x > this.canvas.width - this.canvas.width / (this.scale)) {
        return;
      }
      if (this.scale > 2) {
        this.scale /= 2
      }
      this.prevHideX = x
      this.prevHideY = y
      this.scaleCtx(this.prevHideX, this.prevHideY)
    },


    apply_selection(){
      this.apply(this.currentColor)
      this.ctxHideToCtx()
      this.addInHistory()
    },
    cancel(){
      this.history=this.history.splice(1).concat(this.history);
      this.ctxHide.putImageData(this.history[0], 0, 0)
      this.ctxHideToCtx()
    },
    dualCancel(){
      this.history=this.history.splice(-1).concat(this.history);
      this.ctxHide.putImageData(this.history[0], 0, 0)
      this.ctxHideToCtx()
    },
    hide(){
      if (this.isHide) {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
        // this.setTransparency()
        this.isHide = false
      } else {
        // this.setTransparency()
        this.ctxHideToCtx()
        this.isHide = true
      }
    },
    smartSelection(){
      this.apply(this.colorLabel)
      let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let src = cv.imread('canvas-hide');
      let low = new cv.Mat(src.rows, src.cols, src.type(), [this.colorLabel[0], this.colorLabel[1], this.colorLabel[2], 0]);
      let high = new cv.Mat(src.rows, src.cols, src.type(), [this.colorLabel[0], this.colorLabel[1], this.colorLabel[2], 255]);
      cv.inRange(src, low, high, src);
      let dst = new cv.Mat();
      let kSize = this.borderThickness*2+1
      let M = cv.Mat.ones(kSize, kSize, cv.CV_8U);
      let anchor = new cv.Point(-1, -1);
      cv.erode(src, dst, M, anchor, 1, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());

      let pixelsBorder = src.data
      let pixelsArea = dst.data
      let classes_color = this.classes[0].color
      let color = this.currentColor
      for (let i=0; i<image.data.length; i=i+4){
        if (pixelsBorder[i/4]===255){
          this.setColorPixel(image.data, i, classes_color, 1)
        }
        if (pixelsArea[i/4]===255){
          this.setColorPixel(image.data, i, color, 1)
        }
      }
      this.ctxHide.putImageData(image,0,0)
      this.ctxHideToCtx()
      this.addInHistory()
      src.delete(); dst.delete(); low.delete(); high.delete();
    },

    eraseMask(){
      this.changeColor([0,0,0,0],this.colorLabel)
      this.addInHistory()

    },
    clear(){
      this.ctxHide.clearRect(0, 0, this.canvasHide.width, this.canvasHide.height)
      this.ctxHideToCtx()
      this.addInHistory()
    },
    save(){
      let imageData = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let pixels = imageData.data
      for(let i=3;i<pixels.length;i=i+4){
        pixels[i] = 255;
      }
      this.ctxHide.putImageData(imageData, 0, 0);
      let data = this.canvasHide.toDataURL("imag/png")
      let a = document.createElement("a")
      a.href = data
      a.download = "labels.png"
      a.click()
      this.setTransparency()
    },

    changeClass(idx){
      const classes = document.querySelectorAll('.class-app-item__checkbox')
      for (let i =0;i<classes.length;i++){
        classes[i].checked = i === idx;
      }
      this.currentColor = this.classes[idx].color
    },


    erosion(){
      this.morphologyOperator('erode')
    },
    dilation(){
      this.morphologyOperator('dilate')
    },

    morphologyOperator(operation) {
      let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let src = cv.imread('canvas-hide');
      let color = this.colorLabel
      let low = new cv.Mat(src.rows, src.cols, src.type(), [color[0], color[1], color[2], 0]);
      let high = new cv.Mat(src.rows, src.cols, src.type(), [color[0], color[1], color[2], 255]);
      cv.inRange(src, low, high, src);
      let dst = new cv.Mat();
      let kSize = Number(this.kernelSize);
      let M = cv.Mat.ones(kSize, kSize, cv.CV_8U);
      let anchor = new cv.Point(-1, -1);
      if (operation === 'erode') {
        cv.erode(src, dst, M, anchor, 1, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
      } else {
        cv.dilate(src, dst, M, anchor, 1, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
      }
      let pixels = dst.data
      let currentImageData = this.currentImageData.data

      for (let i = 0; i < image.data.length; i = i + 4) {
        image.data[i] = currentImageData[i]
        image.data[i + 1] = currentImageData[i + 1]
        image.data[i + 2] = currentImageData[i + 2]
        image.data[i + 3] = currentImageData[i + 3]
        if (pixels[i / 4] === 255) {
          this.setColorPixel(image.data, i, color, 1)
        }
      }
      this.ctxHide.putImageData(image, 0, 0)
      this.ctxHideToCtx()
      src.delete();
      dst.delete();
      low.delete();
      high.delete();
    },

    areaFill(){
      let src = cv.imread('canvas-hide');
      let colorLabel = this.colorLabel
      let low = new cv.Mat(src.rows, src.cols, src.type(), [colorLabel[0], colorLabel[1], colorLabel[2], 0]);
      let high = new cv.Mat(src.rows, src.cols, src.type(), [colorLabel[0], colorLabel[1], colorLabel[2], 255]);
      cv.inRange(src, low, high, src);
      let dst = cv.Mat.zeros(src.cols, src.rows, cv.CV_8UC3);
      let contours = new cv.MatVector();
      let hierarchy = new cv.Mat();
      cv.findContours(src, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);
      for (let i = 0; i < contours.size(); ++i) {
        let color = new cv.Scalar(colorLabel[0],colorLabel[1],colorLabel[2]);
        cv.drawContours(dst, contours, i, color, -1, cv.LINE_8, hierarchy, 100);
      }
      cv.cvtColor(dst, dst, cv.COLOR_RGB2RGBA, 0);
      let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let pixels = dst.data

      for (let i=0; i<pixels.length; i=i+4){
        if (this.compare(this.getColorPixel(pixels,i),colorLabel)){
          this.setColorPixel(image.data,i,colorLabel,1)
        }
      }
      this.ctxHide.putImageData(image,0,0)
      this.ctxHideToCtx()
      src.delete(); dst.delete(); contours.delete(); hierarchy.delete();
    },

    highlightColorBtn(){
      this.changeColor(this.colorLabel, this.currentColor)
    },

    changeColor(newColor, currentColor){
      let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let pixelsOriginalImage = image.data
      let src = cv.imread('canvas-hide');
      let dif = 1
      let low = new cv.Mat(src.rows, src.cols, src.type(), [currentColor[0]-dif, currentColor[1]-dif, currentColor[2]-dif, 0]);
      let high = new cv.Mat(src.rows, src.cols, src.type(), [currentColor[0]+dif, currentColor[1]+dif, currentColor[2]+dif, 255]);
      cv.inRange(src, low, high, src);
      let pixels = src.data

      for (let i=0; i<pixelsOriginalImage.length; i=i+4){
        if (pixels[i / 4] === 255) {
          this.setColorPixel(pixelsOriginalImage,i,newColor)
        }
      }
      this.ctxHide.putImageData(image,0,0)
      this.ctxHideToCtx()
      src.delete(); low.delete(); high.delete();
    },

    toolSelected(event){
      let selectedTool = event.target.id
      let isSelected = false
      for (let i = 0;i<this.toolsBtn.length;i++) {
        if (this.toolsBtn[i].tool === selectedTool && !this.toolsBtn[i].isSelected) {
          this.toolsBtn[i].color = this.colorBtnSelected
          this.toolsBtn[i].isSelected = true
          isSelected = true
        }else{
          this.toolsBtn[i].color = this.colorBtn
          this.toolsBtn[i].isSelected = false
        }
      }
      if(!isSelected){
        this.toolsBtn[0].color = this.colorBtnSelected
        this.toolsBtn[0].isSelected = true
      }
    },

    eraseSelection(points){
      let currentX = parseInt(points[0])
      let currentY = parseInt(points[1])
      if (currentX<0 || currentY<0){
        return
      }
      let dst = parseInt(this.ctxHide.lineWidth/2)
      let image = this.ctxHide.getImageData(currentX-dst, currentY-dst, 2*dst, 2*dst);
      let pixels = image.data
      let canvasHideWidth = this.canvasHide.width
      let currentImageData = this.currentImageData.data
      for (let i = 0; i < pixels.length; i += 4) {
        let x = (i/4)%(2*dst)
        let y = Math.floor((i/4)/(2*dst))
        let currentPixel = this.getColorPixel(currentImageData,(currentY-dst+y)*canvasHideWidth*4+(currentX-dst+x)*4)
        if (this.getDistance([dst,dst],[x, y])<=dst && !this.compare([pixels[i],pixels[i+1],pixels[i+2]],[0,0,0])) {
          this.setColorPixel(pixels,i, currentPixel,1)
          if (this.compare(this.getColorPixel(pixels,i,0),[0,0,0])){
            pixels[i+3]=0
          }
        }
      }
      this.ctxHide.putImageData(image, currentX-dst, currentY-dst);
      this.ctxHideToCtx()
    },



    updateCanvasParameter(urlBack, img) {
      if (!this.isFromPredictModule) {
        this.canvasHide.width = img.width
        this.canvasHide.height = img.height
        this.ctxHide.strokeStyle = this.RGBToHex(this.colorLabel[0], this.colorLabel[1], this.colorLabel[2])
        this.ctxHide.lineCap = "round"
        this.ctxHide.imageSmoothingEnabled = false

      }
      this.scaleCoefficient = img.width / this.canvas.width
    },

    imageLoad(ind, file, imagesBack) {
      imagesBack[ind] = {'cbx': this.cbxImageBack[ind], 'image': new Image(), 'url': ''}
      imagesBack[ind].cbx.disabled = false
      if (this.isDebug) {
        imagesBack[ind].image.src = file
      } else if (file.name.indexOf('http') !== -1) {
        imagesBack[ind].image.crossOrigin = "Anonymous";
        imagesBack[ind].image.src = file.name
      } else {
        imagesBack[ind].image.src = URL.createObjectURL(file);
      }
      imagesBack[ind].url = 'url(' + imagesBack[ind].image.src + ')';
    },

    updateCheckboxesLoad(imagesBack) {
      for (let i = 0; i < imagesBack.length; i++) {
        if (imagesBack[i] !== undefined) {
          imagesBack[i].cbx.checked = true
          this.thisImageBack = imagesBack[i].image
          this.canvas.style.background = imagesBack[i].url;
          this.canvas.style.backgroundSize = `${this.scale / 2 * 100}% ${this.scale / 2 * 100}%`
          return
        }
      }
    },


    compare(color1, color2) {
      for (let i = 0; i < color1.length; i++) {
        if (Math.abs(color1[i] - color2[i]) > 2) {
          return false
        }
      }
      return true
    },

    compareColors(color, colorsArray) {
      for (let c of colorsArray) {
        // if (this.compare([color[0],color[1],color[2]],[c[0],c[1],c[2]])){
        //     return true
        // }
        if ((Math.abs(color[0] - c[0]) + Math.abs(color[1] - c[1]) + Math.abs(color[2] - c[2])) <= 3) {
          return true
        }
      }
      return false
    },

    hexToRgb(hex) {
      let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? [parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ] : null;
    },

    RGBToHex(r, g, b) {
      r = r.toString(16);
      g = g.toString(16);
      b = b.toString(16);
      if (r.length === 1)
        r = "0" + r;
      if (g.length === 1)
        g = "0" + g;
      if (b.length === 1)
        b = "0" + b;
      return "#" + r + g + b;
    },

    setTransparency() {
      let imageData = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      let pixels = imageData.data
      let alpha = this.alpha
      for (let i = 0; i < pixels.length; i += 4) {
        if (!this.compare(this.getColorPixel(pixels, i), [0, 0, 0])) {
          pixels[i + 3] = alpha
        } else {
          pixels[i + 3] = 0
        }
      }
      this.ctxHide.putImageData(imageData, 0, 0);
    },

    ctxHideToCtx() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height)
      if (this.scale === 2) {
        this.ctx.drawImage(this.canvasHide,
            0, 0,
            this.canvas.width, this.canvas.height)
      } else {
        this.ctx.drawImage(this.canvasHide,
            (this.prevHideX * this.scaleCoefficient - this.thisImageBack.width / this.scale),
            (this.prevHideY * this.scaleCoefficient - this.thisImageBack.height / this.scale),
            2 * this.thisImageBack.width / this.scale, 2 * this.thisImageBack.height / this.scale,
            0, 0,
            this.canvas.width, this.canvas.height)
      }
    },

    getColorPixel(image, index, isTransparency = false) {
      if (isTransparency) {
        return [image[index], image[index + 1], image[index + 2], image[index + 3]]
      }
      return [image[index], image[index + 1], image[index + 2]]
    },

    setColorPixel(image, index, color, isTransparency = false) {
      image[index] = color[0]
      image[index + 1] = color[1]
      image[index + 2] = color[2]
      if (color.length === 4) {
        image[index + 3] = 0
      } else if (isTransparency) {
        image[index + 3] = this.alpha
      }
    },

    switchBackgroundImage(urlBack) {
      let posX = this.canvas.style.backgroundPositionX;
      let posY = this.canvas.style.backgroundPositionY;
      this.canvas.style.background = urlBack;
      this.canvas.style.backgroundSize = `${this.scale / 2 * 100}% ${this.scale / 2 * 100}%`
      this.canvas.style.backgroundPositionX = `${posX}`
      this.canvas.style.backgroundPositionY = `${posY}`
    },

    apply(color = this.currentColor) {
      if(color!==this.currentColor){
        let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
        let pixelsOriginalImage = image.data
        let src = cv.imread('canvas-test');
        // let dst = new cv.Mat();
        // let dif = 1
        let low = new cv.Mat(src.rows, src.cols, src.type(), [0, 255, 0, 0]);
        let high = new cv.Mat(src.rows, src.cols, src.type(), [255, 255, 255, 255]);
        cv.inRange(src, low, high, src);
        let pixels = src.data

        for (let i=0; i<pixelsOriginalImage.length; i=i+4){
          if (pixels[i / 4] === 255) {
            this.setColorPixel(pixelsOriginalImage,i,color)
          }
        }

        // let image = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
        // let pixels = image.data
        //
        // for (let i = 0; i < pixels.length; i = i + 4) {
        //   if (!this.compareColors(this.getColorPixel(pixels, i), this.classes_colors)) {
        //     this.setColorPixel(pixels, i, color, false)
        //   }
        // }
        this.ctxHide.putImageData(image, 0, 0)
        this.ctxTest.clearRect(0, 0, this.canvasTest.width, this.canvasTest.height)
        return
      }

      this.changeColor(this.currentColor, this.colorLabel)
    },

    addInHistory() {
      this.currentImageData = this.ctxHide.getImageData(0, 0, this.canvasHide.width, this.canvasHide.height);
      if (this.history.length > this.sizeHistory) {
        this.history.pop()
      }
      this.history.unshift(this.currentImageData)
    },

    getDistance(x, y) {
      return Math.sqrt((x[0] - y[0]) ** 2 + (x[1] - y[1]) ** 2)
    },

    crop(num) {
      if (num < 0) {
        num = 0;
      } else if (num > 255) {
        num = 255
      }
      return num
    }
  },

  computed:{
    ...mapGetters(['getThinSectionPlot']),
  },

  mounted() {
    const classes = document.querySelectorAll('.class-app-item__checkbox')
    classes[1].checked=true
    this.currentColor = this.classes[1].color
    this.classes_colors = this.classes.map(cls => cls.color)
    this.classes_colors.push([0, 0, 0])

    if(this.isDebug) {
      this.uploadImages()
      this.uploadMask()
    }else {
      let url_animation = this.getThinSectionPlot(this.$route.params.id).rotate_action.rotation_animation.image.origin
      let url_overlay_img = this.getThinSectionPlot(this.$route.params.id).predict_action.overlay_image.image.origin
      let url_ll_img = this.getThinSectionPlot(this.$route.params.id).rotate_action.rotated_parallel_image.image.origin
      let images=[
        {
          type:'image/gif',
          name:url_animation
        },
        {
          type:'image/ll',
          name: url_ll_img
        },
        {
          type:'image/png',
          name: url_overlay_img
        },
      ]
      this.uploadImages(images);
      let url_pred_mask = this.getThinSectionPlot(this.$route.params.id).predict_action.masks[0].image.origin
      let url_pred_mask_instances = this.getThinSectionPlot(this.$route.params.id).predict_action.masks[1].image.origin
      let images_masks=[
        {
          type:'image/pred_mask',
          name: url_pred_mask
        },
        {
          type:'image/pred_mask_instances',
          name: url_pred_mask_instances
        }
      ]
      this.uploadMask(images_masks);
    }

    let canvas = this
    let currentKeysPress = []
    let hotKeysElems = document.getElementsByName('hotkey')
    let hotKeys = []
    let hotKeysDefault = []

    for(let i=0;i<hotKeysElems.length;i++){
      let hotKey = hotKeysElems[i].title.split(': ')[1].split('+')
      hotKeys[i] = {'element': hotKeysElems[i].id, 'elementName': hotKeysElems[i].innerHTML, 'hotkey': hotKey}
      hotKeysDefault[i] = {'element': hotKeysElems[i].id, 'elementName': hotKeysElems[i].innerHTML, 'hotkey': hotKey}
      let option = document.createElement('option')
      option.innerHTML = hotKeys[i].elementName
    }

    window.addEventListener('keydown', function(event) {

      if (canvas.cursorRounded.style.display === 'none') {
        return
      }
      event.preventDefault()
      if(event.code.indexOf('Key')!==-1) {
        currentKeysPress.push(event.code.slice(3))
      }else{
        if(event.key==='Control') {
          currentKeysPress.push('Ctrl')
        } else if(event.code==='Space') {
          currentKeysPress.push(event.code)
        } else {
          currentKeysPress.push(event.key)
        }
      }
      // inputHotKey.placeholder=addPlusToHotKey(currentKeysPress)

      for(let i=0;i<hotKeysElems.length;i++){
        let hotKey = hotKeys[i]['hotkey']
        let isOk = true
        if(currentKeysPress.length!==hotKey.length){
          continue
        }
        for (let j=0;j<hotKey.length;j++){
          if(currentKeysPress.indexOf(hotKey[j])===-1){
            isOk=false
            break
          }
        }
        if (isOk){
          document.querySelector('#'+hotKeys[i]['element']).click()
        }
      }
    });

    window.addEventListener('keyup', function(event) {
      for(let i=0;i<currentKeysPress.length;i++){
        if(currentKeysPress[i] !== 'Ctrl'){
          currentKeysPress.splice(i,1)
        }
      }
      if (event.key==='Control'){
        currentKeysPress = []
      }
    });

    this.cursorRounded = document.querySelector("#cursor-rounded")
  }
}
</script>

<style scoped lang="scss">
.save-button {
  background-color: #20DF7F;
  font-weight: 600;
  padding: 1.5rem;
  border-radius: .5rem;
  width: 100%;
  font-size: 18px;
  margin-right: 30px;
}
</style>