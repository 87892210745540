import authHttpClient from "@/http/auth";

class UserService {
    getDetails() {
        return authHttpClient.get('details/');
    }

    logout() {
        return authHttpClient.post('logout/');
    }
}

export default new UserService();