<template>
  <v-form
      class="tectonic-form-container container d-flex flex-column"
      v-model="isValid"
      ref="form"
      @submit.prevent="addDeposit"
  >
    <v-text-field
        label="Введите название месторождения"
        v-model="name"
        autofocus
        :rules="rules"
    ></v-text-field>
    <v-btn @click="addDeposit">
      {{ buttonText }}
    </v-btn>
  </v-form>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import depositService from "@/http/service/deposit-service";

export default {
  name: 'DepositCreateUpdateForm',
  props: {
    tectonicId: {
      required: true,
      type: [String, Number]
    },
    update: {
      default: false
    },
    // used for update action, otherwise it's undefined
    depositId: {
      required: false,
      type: [String, Number]
    }
  },
  data() {
    return {
      isValid: true,
      rules: [v => v !== null && v.length > 0 || 'Название не должно быть пустым'],
      name: ''
    }
  },
  created() {
    console.log(this.deposit)
    this.name = this.deposit?.name || ''
  },
  computed: {
    ...mapGetters(['getDeposit']),
    deposit() {
      console.log(this.getDeposit(this.depositId))
      return this.getDeposit(this.depositId)
    },
    buttonText() {
      return this.update ? 'Обновить' : 'Добавить'
    }
  },
  watch: {
    $props: {
      immediate: true,
      handler() {
        this.validateProps();
      }
    }
  },
  methods: {
    ...mapMutations(['ADD_DEPOSIT', 'UPDATE_DEPOSIT']),
    validateProps() {
      if (this.update && !this.depositId) {
        throw new Error("The 'deposit-id' props should be passed if 'update === true'")
      }
    },
    addDeposit() {
      this.$refs.form.validate()
      if (!this.isValid) {
        return
      }

      let response = null

      if (this.update) {
        response = depositService.update(
            {
              name: this.name
            },
            this.depositId
        )
      } else {
        response = depositService.add({
          name: this.name,
          tectonic_confinement: this.tectonicId
        })
      }

      response.then((data) => {
        this.$refs.form.reset()
        if (!this.update) {
          this.$router.push({name: 'Deposit', params: {id: data.id}});
        }
      }).finally(() => {
        this.$emit("close-window")
      })
    }
  }
}
</script>