export default {
    state: {
        userDetails: {
            firstName: '',
            lastName: '',
            isActivated: false
        }
    },
    mutations: {},
    actions: {
        authenticated() {
            window.localStorage.setItem("auth", JSON.stringify(true))
        },
        logout() {
            window.localStorage.setItem("auth", JSON.stringify(false))
        },
        is_authenticated() {
            return JSON.parse(window.localStorage.getItem('auth')) || false;
        },
        isActivated({state}) {
            return state.userDetails.isActivated;
        }
    },
    getters: {}
}
