<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="450"
      @click:outside="closeDialog"
  >
    <v-card height="600">
      <v-card-title class="text-center" text>
        Выберите необходимые классы <br> и их границы
      </v-card-title>
      <v-card-text>
        <!--          <v-checkbox v-model="parameters.isClassification" :label="`Определить автоматически`"></v-checkbox>-->
        <v-row class="text-center">
          <v-col class="py-0" cols="6">
            <v-card-text class="text-h5 pb-0">Минералы</v-card-text>
          </v-col>
          <v-col class="py-0" cols="6">
            <v-card-text class="text-h5 pb-0">Границы</v-card-text>
          </v-col>
        </v-row>

        <v-row class="justify-center text-center">
          <v-col cols="6">
            <a href="#"
               @click="$emit('check-all')"
            >Выбрать все</a>
          </v-col>
          <v-col cols="6">
            <a href="#"
               @click="$emit('uncheck-all')"
            >Убрать все</a>
          </v-col>
        </v-row>

        <InputParameterModelsItem
            v-for="cls in parameters.classes.slice(2).filter(obj => obj.name !== 'Неопределенные области')"
            :key="cls.name"
            v-bind:parameters="cls"
            v-bind:is-classification="parameters.isClassification"
        />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green darken-1"
               text
               @click="dialog=false">
          Отмена
        </v-btn>
        <v-btn color="red"
               text
               @click="confirmed">
          Запуск анализа
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import InputParameterModelsItem from "@/components/InputParameterModelsItem.vue";

export default {
  name: "InputParameterModelsDialog",
  props: {
    onConfirm: {
      type: Function,
      required: true
    },
    parameters: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      dialog: null,
      checkbox: true,
    }
  },
  components: {
    InputParameterModelsItem
  },
  methods: {
    async confirmed() {
      await this.onConfirm()
      this.dialog = false
    },
    closeDialog() {
      this.dialog = false;
    }
  }
}
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.v-card {
  display: flex !important;
  flex-direction: column;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}
</style>