<template>
  <v-container fluid style="position: relative">
    <table v-if="showLegend" class="legend">
      <thead>
      <tr>
        <th style="width: 75px">Цвет</th>
        <th>Класс</th>
      </tr>
      </thead>
      <tbody>
      <tr
          v-for="granulometricAnalyze of granulometricAnalyzeAction.results"
          :key="granulometricAnalyze.id"
      >
        <td :style="{ backgroundColor: classToColor(granulometricAnalyze.num_of_class) }"></td>
        <td
            style="padding-left: 2rem;"
        >{{ granulometricAnalyze.num_of_class | classToName }}
        </td>
      </tr>
      </tbody>
    </table>
    <template v-if="predictAction">
      <v-row
          class="analyze-result-title mb-2"
      >
        результаты сегментации
      </v-row>
      <v-row class="mb-3">
        <v-col cols="auto" class="pl-0">
          <v-card
              width="300"
              class="image-card"
          >
            <img
                v-img="{
              src: predictAction.masked_overlay_image.image.origin,
              opened: () => {this.showLegend = true},
              closed: () => {this.showLegend = false}
            }"
                style="width: 100%; height: 250px"
                :src="predictAction.masked_overlay_image.image.compressed" alt=""
            >
            <v-card-title
                class="image-card__title justify-center"
            >
              Маска
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="auto" class="pl-0">
          <v-card
              width="300"
              class="image-card"
          >
            <img v-img="{
              src: predictAction.masked_rotation_animation.image.origin,
              opened: () => {this.showLegend = true},
              closed: () => {this.showLegend = false}
            }" style="width: 100%; height: 250px"
                 :src="predictAction.masked_rotation_animation.image.origin" alt="">
            <v-card-title
                class="image-card__title justify-center"
            >
              Анимация
            </v-card-title>
          </v-card>
        </v-col>
        <v-col cols="auto" class="pl-0">
          <v-card
              width="300"
              class="image-card"
          >
            <img v-img="{src: predictAction.overlay_image.image.origin}" style="width: 100%; height: 250px"
                 :src="predictAction.overlay_image.image.compressed" alt="">
            <v-card-title
                class="image-card__title justify-center"
            >
              Overlay Image
            </v-card-title>
          </v-card>
        </v-col>
        <v-col>
          <button class="annotation-module-button" @click="openAnnotationToll">
            Редактировать маску
          </button>
        </v-col>
      </v-row>
    </template>

    <template v-if="granulometricAnalyzeAction">
      <v-row
          class="analyze-result-title mb-2 align-start"
      >
        <v-col
            class="pl-0"
            cols="auto"
        >
          гранулометрический анализ
        </v-col>
        <v-col
            class="pl-0 download-results-button"
            cols="auto"
            align-self="center"
        >
          <v-btn
              @click.prevent="downloadResults"
              color="#20DF7F"
          >
            Скачать результаты
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <table class="table">
          <thead class="thead-dark">
          <tr>
            <th scope="col">Область</th>
            <th scope="col">Цвет</th>
            <th scope="col">0.01-0.05 мм</th>
            <th scope="col">0.05-0.1 мм</th>
            <th scope="col">0.25-0.5 мм</th>
            <th scope="col">&lt; 0.01 мм</th>
            <th scope="col">&gt; 0.25 мм</th>
            <th scope="col">Коэффициент<br> по Траску</th>
            <th scope="col">Медианный<br>диаметр (мм)</th>
            <th scope="col">Процентное<br>содержание (%)</th>
            <th scope="col">Cтепень отсортиро-<br>ванности</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="granulometricAnalyze of granulometricAnalyzeAction.results"
              :key="granulometricAnalyze.id"
          >
            <td>{{ granulometricAnalyze.num_of_class | classToName }}</td>
            <td :style="{ backgroundColor: classToColor(granulometricAnalyze.num_of_class) }"></td>
            <td>{{ granulometricAnalyze.k_005_001 | round }}</td>
            <td>{{ granulometricAnalyze.k_005_01 | round }}</td>
            <td>{{ granulometricAnalyze.k_05_025 | round }}</td>
            <td>{{ granulometricAnalyze.k_less_0_01 | round }}</td>
            <td>{{ granulometricAnalyze.k_more0_25 | round }}</td>
            <td>{{ granulometricAnalyze.k_trusk | round }}</td>
            <td>{{ granulometricAnalyze.med_dim | round }}</td>
            <td>{{ granulometricAnalyze.percentage | round }}</td>
            <td>{{ granulometricAnalyze.st_sort | round }}</td>
          </tr>
          </tbody>
        </table>
      </v-row>
    </template>

    <template v-if="rotateAction">
      <v-row
          class="analyze-result-title mb-1"
      >
        результаты приведения к общей системе координат
      </v-row>

      <v-row
          v-if="rotateAction"
          class="analyze-result-title mb-1"
      >
        Параллельные николи
      </v-row>

      <v-row class="mb-3">
        <v-card
            width="300"
            class="image-card"
        >
          <img v-img="{src: rotateAction.rotated_parallel_image.image.origin}" style="width: 100%; height: 250px"
               :src="rotateAction.rotated_parallel_image.image.compressed" alt="">
          <v-card-title
              class="image-card__title justify-center"
          >
            Масштаб: {{ rotateAction.rotated_parallel_image.scale }} мм
          </v-card-title>
        </v-card>
      </v-row>

      <v-row
          v-if="rotateAction"
          class="analyze-result-title mb-1"
      >
        скрещенные николи
      </v-row>


      <v-row>
        <v-col
            cols="auto"
            class="pl-0"
        >
          <v-card
              width="300"
              class="image-card"
          >
            <img v-img="{src: rotateAction.rotation_animation.image.origin}" style="width: 100%; height: 250px"
                 :src="rotateAction.rotation_animation.image.origin" alt="">
            <v-card-title
                class="image-card__title justify-center"
            >
              Анимация
            </v-card-title>
          </v-card>
        </v-col>

        <v-col
            v-for="image in sortedRotatedCrossedImages"
            :key="image.id"
            cols="auto"
            class="pl-0"
        >
          <v-card
              width="300"
              class="image-card"
          >
            <img v-img="{src: image.image.origin}" style="width: 100%; height: 250px" :src="image.image.compressed"
                 alt="">
            <v-card-title
                class="image-card__title justify-center"
            >
              Угол поворота: {{ image.grade }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row>
    </template>

  </v-container>
</template>

<script>
import thinSectionPlotService from "@/http/service/thin-section-plot-service";

export default {
  name: "ResultsTab",
  data() {
    return {
      showLegend: false,
    }
  },
  props: {
    thinSectionPlot: null,
    rotateAction: null,
    predictAction: null,
    granulometricAnalyzeAction: null,
  },
  filters: {
    round(num) {
      const numObject = Number(num)
      return numObject.toFixed(2);
    },
    classToName(classNumber) {
      const classNames = ['Поры', 'Границы', 'Кварц', 'Слюда', 'Рудные минералы',
        'Глина', 'Карбонаты', 'Глинистый цемент', 'Неопределенные области', 'Спарит', 'Микрит', 'Остатки организмов']
      if (classNumber < 0 || classNumber > classNames.length) {
        return classNumber
      }
      return classNames[classNumber]
    }
  },
  computed: {
    sortedRotatedCrossedImages() {
      return [...this.rotateAction.rotated_crossed_images].sort((a, b) => {
        return a.grade - b.grade
      })
    }
  }
  ,
  methods: {
    openAnnotationToll() {
      this.$router.push({
        name: 'Annotation Tool'
      })
    },
    classToColor(classNumber) {
      const classesColors = ['black', 'yellow', 'pink', 'orange', 'green',
        'red', 'blue', 'white', 'purple', 'aqua', 'skyblue', 'brown'];
      if (classNumber < 0 || classNumber > classesColors.length) {
        return 'unknown'
      }
      return classesColors[classNumber]
    },
    async downloadResults() {
      await thinSectionPlotService
          .downloadGranulometricAnalyzeResults(
              this.thinSectionPlot.id,
              this.thinSectionPlot.name + ' результаты анализа.xlsx'
          );
    }
  }
}
</script>

<style scoped lang="scss">
.analyze-result-title {
  font-size: 25px;
  text-transform: uppercase;
}

.download-results-button {
  font-size: 15px;
}

.table {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid black;
  border-collapse: collapse;
}

.table th {
  font-weight: bold;
  padding: 5px;
  border: 1px solid black;
}

.table td {
  padding: 5px;
  border: 1px solid black;
}

.annotation-module-button {
  background-color: #20DF7F;
  font-weight: 600;
  padding: 1.5rem;
  border-radius: .5rem;
  width: 100%;
  font-size: 15px;
}

.legend {
  position: absolute;
  padding: 2rem;
  border-radius: 0.5rem;
  top: 0vh;
  right: 0;
  transform: translateY(0, -50%);
  z-index: 10000;
  background-color: white;
}
</style>