import Vue from 'vue'
import Vuex from 'vuex'
import User from "./modules/user"
import NavigationList from "./modules/navigation"


Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user: User,
        navigation: NavigationList,
    }
})

export default store;
