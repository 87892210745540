<template>
  <v-container fluid class="fill-height">
    <v-row class="justify-center align-center" no-gutters>
      <v-col cols="auto">
        <img src="@/assets/img/activate.png" alt="actiavte">
      </v-col>
     <v-col cols="4" class="text-center activate-information">
       Уважаемый пользователь, <br>
       вы успешно прошли регистрацию. <br>
       Дождитесь пока администрация активирует аккаунт.
     </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AccountNotActivated"
}
</script>

<style scoped lang="scss">

.activate-information {
  font-size: 30px;
  font-weight: bold;
}

</style>