<template>
  <v-container fluid class="deposit-wrapper pa-5">
    <v-row>
      <v-col cols="10">
        <div class="domain-header-text">
          Данные о месторождении
        </div>
        <DepositCard :id="Number(id)"/>
        <div class="domain-child">
          Скважины
        </div>
        <div class="domain-child__item"
             v-for="well of wells"
             :key="well.id"
             @click="navigateTo(well)"
        >{{ well.name }}
        </div>
      </v-col>
      <v-col cols="2">
        <v-row>
          <WellCreateUpdateModalWindow
            :deposit-id="id"
          ></WellCreateUpdateModalWindow>
        </v-row>
        <v-row class="mt-5">
          <DepositCreateUpdateModalWindow
            :tectonic-id="deposit.tectonic_confinement"
            :update="true"
            :deposit-id="deposit.id"
          />
        </v-row>
        <v-row class="mt-5">
          <DeleteConfirmDialog
            :modal-name="'месторождение'"
            :on-confirm="deleteDeposit"
          />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import WellCreateUpdateModalWindow from "@/components/modal/list/WellCreateUpdateModalWindow.vue";
import {mapGetters, mapMutations} from "vuex";
import depositService from "@/http/service/deposit-service";
import DepositCard from "@/components/domain/DepositCard.vue";
import DeleteConfirmDialog from "@/components/DeleteConfirmDialog.vue";
import DepositCreateUpdateModalWindow from "@/components/modal/list/DepositCreateUpdateModalWindow.vue";

export default {
  name: "DepositView",
  components: {DepositCreateUpdateModalWindow, DeleteConfirmDialog, DepositCard, WellCreateUpdateModalWindow},
  props: ['id'],
  methods: {
    ...mapMutations(['COLLAPSE_DEPOSIT']),
    navigateTo(item) {
      this.$router.push(item.link)
    },
    async deleteDeposit() {
      await depositService.delete(this.deposit)
    }
  },
  computed: {
    ...mapGetters(['depositWells', 'getDeposit']),
    deposit() {
      return this.getDeposit(this.id)
    },
    wells() {
      return this.depositWells(this.id)
    },
  },
  watch: {
    id: {
      handler: function () {
        this.COLLAPSE_DEPOSIT(this.id)
      },
      immediate: true
    }
  }
}
</script>

<style scoped lang="scss">

</style>