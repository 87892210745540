import router from "@/router";
import store from "@/store";
import apiInstance from "@/http";

class SampleService {
    add(data) {
        return apiInstance.post('sample/', data)
            .then(({data}) => {
                store.commit('ADD_SAMPLE', data)
            }).catch(err => console.log(err))
    }

    updateDescription(sample, newDescription) {
        return apiInstance.patch('sample/' + sample.id + '/', {
            description: newDescription
        })
    }

    async delete(sample) {
        await apiInstance.delete('sample/' + sample.id)
        await router.push('/well/' + sample.well)
        store.commit('DELETE_SAMPLE', sample.id)
    }
}

export default new SampleService()