<template>
  <v-dialog
    v-model="value"
    max-width="500px">
    <template v-slot:activator="{ on }">
      <button
        v-if="update"
        class="domain-edit-button"
        v-on="on"
      >
        Редактировать
      </button>
      <button
        v-else
        class="domain-add-button"
        v-on="on"
      >Добавить образец
      </button>
    </template>
    <v-card>
      <SampleCreateUpdateForm
        :well-id="wellId"
        :update="update"
        :sample-id="sampleId"
        @close-window="close"
      ></SampleCreateUpdateForm>
    </v-card>
  </v-dialog>
</template>

<script>
import SampleCreateUpdateForm from "@/components/modal/form/SampleCreateUpdateForm.vue";

export default {
  name: "SampleCreateUpdateModalWindow",
  components: {SampleCreateUpdateForm},
  props: {
    wellId: {
      required: true,
      type: [Number, String]
    },
    update: {
      default: false
    },
    // used for update action, otherwise it's undefined
    sampleId: {
      required: false,
      type: [Number, String]
    },
  },
  data() {
    return {
      value: false,
    }
  },
  methods: {
    close() {
      this.value = false
    }
  },
}
</script>
